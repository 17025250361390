import React from 'react';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import moment from 'moment';
import Row from '@amzn/meridian/row';
import { logger } from 'src/logger';
import { getLocalizedMonthDayString, getLocalizedHourString, DayFormat } from 'src/utils/Constants';
import { getTranslationFromBundle, t } from 'src/helpers';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { MessageBundle } from '@amzn/arb-tools';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function statusComponent(header: string | undefined, subheader: string | undefined) {
  return (
    <Column spacing="none">
      <Row spacing="none">
        <Text type="h400">{header}</Text>
      </Row>
      <Row>
        <Text type="b300">{subheader ? subheader : ''}</Text>
      </Row>
    </Column>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function deliveredStatus(
  deliveredDate: Date | undefined,
  itemsCount: number,
  bundle: MessageBundle
) {
  const deliveredHeader = getTranslationFromBundle(
    bundle,
    ['swiship_order_item_delivered', 'swiship_order_items_delivered'],
    { items: itemsCount.toString() },
    itemsCount
  );
  const dateSubheader = deliveredDate
    ? getLocalizedMonthDayString(deliveredDate, DayFormat.long)
    : '';

  return statusComponent(deliveredHeader, dateSubheader);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function deliveryFailedStatus(
  transitState: string | undefined,
  itemsCount: number,
  bundle: MessageBundle
) {
  const itemsHeader: string | undefined =
    itemsCount == null
      ? t(bundle, 'swiship_package_capitalized')
      : getTranslationFromBundle(
          bundle,
          ['swiship_order_item', 'swiship_order_items'],
          { items: itemsCount.toString() },
          itemsCount
        );

  return statusComponent(itemsHeader, transitState);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function arriveInStatus(
  estimatedDeliveryDate: Date | undefined,
  transitState: string | undefined,
  itemsCount: number,
  bundle: MessageBundle
) {
  if (!estimatedDeliveryDate) {
    const header = getTranslationFromBundle(
      bundle,
      ['swiship_order_item', 'swiship_order_items'],
      { items: itemsCount.toString() },
      itemsCount
    );

    return statusComponent(header, transitState);
  }

  const estimatedDate = moment(estimatedDeliveryDate);
  const tomorrowDate = moment().add(1, 'd');
  const isEstimatedDateInPast = estimatedDeliveryDate.getTime() < new Date().getTime();

  const isDeliveryToday = estimatedDate.isSame(moment(), 'day');
  const isDeliveryTomorrow = estimatedDate.isSame(tomorrowDate, 'day');

  let arrivesHeader: string | undefined;
  if (isDeliveryToday) {
    const isTimeAvailable = estimatedDate.hour() > 0 || estimatedDate.minute() > 0;

    // Rounding delivery estimated time to start of the hour
    const roundedEstimatedTime = moment(estimatedDate).startOf('hour');

    getTranslationFromBundle(
      bundle,
      ['swiship_order_item', 'swiship_order_items'],
      { items: itemsCount.toString() },
      itemsCount
    );

    arrivesHeader = isTimeAvailable
      ? getTranslationFromBundle(
          bundle,
          ['swiship_order_item_arrives_today_by', 'swiship_order_items_arrive_today_by'],
          {
            items: itemsCount.toString(),
            time: getLocalizedHourString(roundedEstimatedTime),
          },
          itemsCount
        )
      : getTranslationFromBundle(
          bundle,
          ['swiship_order_item_arrives_today', 'swiship_order_items_arrive_today'],
          { items: itemsCount.toString() },
          itemsCount
        );
  } else if (isDeliveryTomorrow) {
    arrivesHeader = getTranslationFromBundle(
      bundle,
      ['swiship_order_item_arrives_tomorrow', 'swiship_order_items_arrive_tomorrow'],
      { items: itemsCount.toString() },
      itemsCount
    );
  } else {
    arrivesHeader = getTranslationFromBundle(
      bundle,
      isEstimatedDateInPast
        ? ['swiship_order_item', 'swiship_order_items']
        : ['swiship_order_item_arrives', 'swiship_order_items_arrives'],
      {
        items: itemsCount.toString(),
        date: estimatedDate.fromNow(),
      },
      itemsCount
    );
  }

  const deliveryDateString = isEstimatedDateInPast
    ? undefined
    : getTranslationFromBundle(bundle, ['swiship_order_delivery'], {
        date: ' ' + getLocalizedMonthDayString(estimatedDeliveryDate),
      });

  const stateSubheader =
    (transitState ? transitState + (deliveryDateString ? '. ' : '') : '') +
    (deliveryDateString ? deliveryDateString : '');

  return statusComponent(arrivesHeader, stateSubheader);
}

export const PackageStatusComponent = observer(({ packageInfo }: any) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

  if (!packageInfo) {
    logger.warn('PackageStatusComponent: package information are missing');
    return null;
  }

  const itemsCount = packageInfo.getItemCount;

  let transitState: string | undefined;
  switch (packageInfo.getTransitState) {
    case 'OUT_FOR_DELIVERY':
      transitState = t(bundle, 'swiship_out_for_delivery');
      break;
    case 'AWAITING_PICKUP_BY_CARRIER':
      transitState = t(bundle, 'swiship_awaiting_pickup_by_carrier');
      break;
    case 'DELIVERY_ATTEMPTED':
      transitState = t(bundle, 'swiship_delivery_attempted');
      break;
    case 'UNDELIVERABLE':
      return deliveryFailedStatus(t(bundle, 'swiship_undeliverable'), itemsCount, bundle);
    case 'RETURNING':
      return deliveryFailedStatus(t(bundle, 'swiship_returning'), itemsCount, bundle);
    case 'DELIVERED':
      const allEvents = packageInfo.getTrackingDetail.getAllEvents;
      return deliveredStatus(
        allEvents[0] ? allEvents[0].getEventDate : undefined,
        itemsCount,
        bundle
      );
    case 'IN_TRANSIT':
      transitState = t(bundle, 'swiship_package_in_transit');
      break;
    case 'AVAILABLE_FOR_PICKUP':
      transitState = t(bundle, 'swiship_available_for_pickup');
      break;
    case 'CUSTOMER_ACTION_REQUIRED':
      return deliveryFailedStatus(
        t(bundle, 'swiship_customer_action_required'),
        itemsCount,
        bundle
      );
    case 'DELAYED':
      transitState = t(bundle, 'swiship_delayed');
      break;
  }

  return arriveInStatus(
    packageInfo.getEstimatedArrivalDateInDateFormat,
    transitState,
    itemsCount,
    bundle
  );
});

export default PackageStatusComponent;
