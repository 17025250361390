import { useLocation, NavigateFunction } from 'react-router-dom';
import {
  DESIGN_ID_QUERY_PARAM,
  LOCALE_QUERY_PARAM,
  TRACKING_ID_QUERY_PARAM,
} from 'src/utils/Constants';

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const changeURL = (
  navigate: NavigateFunction,
  locale?: string,
  trackingId?: string,
  newDesign?: boolean
): void => {
  let query = '';

  function addParam(to: string, param: string, value?: string): string {
    return (
      (to.length === 0 ? '?' : '') +
      to +
      (to.length > 0 ? '&' : '') +
      param +
      (value ? '=' + value : '')
    );
  }

  query = locale ? addParam(query, LOCALE_QUERY_PARAM, locale) : query;
  query = trackingId ? addParam(query, TRACKING_ID_QUERY_PARAM, trackingId) : query;
  query = newDesign ? addParam(query, DESIGN_ID_QUERY_PARAM) : query;

  navigate({ search: query });
};
