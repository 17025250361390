import React from 'react';
import * as KatalMetrics from '@amzn/katal-metrics';
import initialMetricsPublisher from '../metrics';

enum InitializationStatus {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
}

// eslint-disable-next-line
export class AppInitWrapper extends React.Component<PropsType, {}> {
  private initializationMetric = new KatalMetrics.Metric.Initialization().withMonitor();
  private initializationMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForInitialization();
  private errorBoundaryMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('ErrorBoundary');
  private isInitialized = false;

  componentDidMount(): void {
    this.logInitializationMetric(InitializationStatus.Success);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, info: React.ErrorInfo): never {
    this.logInitializationMetric(InitializationStatus.Failure);
    this.errorBoundaryMetricsPublisher.publishCounterMonitor('ErrorBoundary.Encounter', 1);
    // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
    throw error;
  }

  render(): JSX.Element {
    return this.props.children;
  }

  // Helper method to publish the initialization metric
  private logInitializationMetric(status: InitializationStatus): void {
    if (!this.isInitialized && this.initializationMetric && this.initializationMetricsPublisher) {
      this.isInitialized = true;
      this.initializationMetric.setFailure(status !== InitializationStatus.Success);
      this.initializationMetricsPublisher.publish(this.initializationMetric);
    }
  }
}

export default AppInitWrapper;
