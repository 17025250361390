import { makeObservable, observable, action, flow, runInAction } from 'mobx';
import { DATE_AND_TIME } from 'src/utils/Formatters';
import { TrackingDetail } from 'src/stores/TrackingDetail';
import { TrackingEvent } from 'src/stores/TrackingEvent';
import { STATES } from 'src/utils/Constants';
import { getPackageTrackingDetails } from 'src/utils/api';
import { isWithin120Days, parseISOString } from 'src/utils/Timer';
import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricTimedAttempt from '@amzn/katal-metrics/lib/metricObject/KatalMetricTimedAttempt';
import { logger } from 'src/logger';
import { AxiosResponse } from 'axios';

interface PackageLockerDetails {
  lockerNumber: string;
  lockerAccessCode: string;
}

export interface PackageDeliveryWindow {
  startDate: string;
  endDate: string;
}

export interface DeliveryDetails {
  lockerDetails: PackageLockerDetails;
  deliveryWindow: PackageDeliveryWindow;
  packageDropOffLocation: string;
}

export class PackageInfo {
  @observable protected _loadingState: string = STATES.PENDING;
  @observable protected _trackingNumber = '';
  @observable protected _displayableCarrierName = '';
  @observable protected _shipMethod = '';
  @observable protected _estimatedArrivalDate: Date | undefined = undefined;
  @observable protected _transitState = '';
  @observable protected _trackingDetail: TrackingDetail = new TrackingDetail();
  @observable protected _deliveryDetails: DeliveryDetails = {
    lockerDetails: {
      lockerNumber: '',
      lockerAccessCode: '',
    },
    deliveryWindow: {
      startDate: '',
      endDate: '',
    },
    packageDropOffLocation: '',
  };
  @observable protected _hasAdditionalDeliveryDetails = false;
  @observable protected _itemCount = 1;
  @observable protected _scannedDate: Date | undefined = undefined;
  @observable protected _isShipped = true;
  protected packageTrackingDetail: AxiosResponse | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setLoadingState(loadingState: string): void {
    this._loadingState = loadingState;
  }

  get getLoadingState(): string {
    return this._loadingState;
  }

  @action
  setTrackingNumber(trackingNumber: string): void {
    this._trackingNumber = trackingNumber;
  }

  get getTrackingNumber(): string {
    return this._trackingNumber;
  }

  @action
  setDisplayableCarrierName(displayableCarrierName: string): void {
    this._displayableCarrierName = displayableCarrierName;
  }

  get getDisplayableCarrierName(): string {
    return this._displayableCarrierName;
  }

  @action
  setShipMethod(shipMethod: string): void {
    this._shipMethod = shipMethod;
  }

  get getShipMethod(): string {
    return this._shipMethod;
  }

  @action
  setEstimateArrivalDate(estimatedArrivalDate: Date): void {
    this._estimatedArrivalDate = estimatedArrivalDate;
  }

  get getEstimatedArrivalDate(): string {
    return this._estimatedArrivalDate !== undefined
      ? DATE_AND_TIME.format(this._estimatedArrivalDate)
      : '';
  }

  get getEstimatedArrivalDateInDateFormat(): Date | undefined {
    return this._estimatedArrivalDate;
  }

  @action
  setTransitState(transitState: string): void {
    this._transitState = transitState;
  }

  get getTransitState(): string {
    return this._transitState;
  }

  @action
  setTrackingDetail(trackingDetail: TrackingDetail): void {
    this._trackingDetail = trackingDetail;
  }

  get getTrackingDetail(): TrackingDetail {
    return this._trackingDetail;
  }

  @action
  setDeliveryDetails(deliveryDetails: DeliveryDetails): void {
    this._deliveryDetails = deliveryDetails;
  }

  get getDeliveryDetails(): DeliveryDetails {
    return this._deliveryDetails;
  }

  @action
  setAdditionalDeliveryDetails(hasAdditionalDeliveryDetails: boolean): void {
    this._hasAdditionalDeliveryDetails = hasAdditionalDeliveryDetails;
  }

  get getAdditionalDeliveryDetails(): boolean {
    return this._hasAdditionalDeliveryDetails;
  }

  @action
  setItemCount(itemCount: number): void {
    this._itemCount = itemCount;
  }

  get getItemCount(): number {
    return this._itemCount;
  }

  @action
  setScannedDate(scannedDate: Date | undefined): void {
    this._scannedDate = scannedDate;
  }

  get getScannedDate(): string | undefined {
    return this._scannedDate !== undefined ? DATE_AND_TIME.format(this._scannedDate) : '';
  }

  @action
  setIsShipped(isShipped: boolean): void {
    this._isShipped = isShipped;
  }

  get getIsShipped(): boolean {
    return this._isShipped;
  }

  protected static handleErrors = (
    packageInfo: PackageInfo,
    error: any,
    metric: KatalMetricTimedAttempt,
    trackingNumber: string
  ): void => {
    /** client errors */
    if (error.response && error.response.status >= 400 && error.response.status < 500) {
      if (packageInfo._estimatedArrivalDate && isWithin120Days(packageInfo._estimatedArrivalDate)) {
        packageInfo.setLoadingState(STATES.TRACKING_TOO_EARLY);
        packageInfo.setTransitState('IN_TRANSIT');
        logger.info(
          'tracking too early for tracking id:' + trackingNumber + ' with error:' + error
        );
      } else {
        packageInfo.setLoadingState(STATES.OLD_TRACKING_ID_OR_TRACKING_DATA_NOT_FOUND);
        logger.info('Invalid tracking id:' + trackingNumber + ' with error:' + error);
      }
      metric.setSuccess();
      return;
    }
    /** any other error */
    packageInfo.setLoadingState(STATES.ERROR);
    metric.setFailure();
    logger.error(
      'Error fetching package tracking details for tracking id:' +
        trackingNumber +
        ' with error:' +
        error
    );
  };

  fetchPackageTrackingDetails = flow(function* (this: PackageInfo) {
    if (this._loadingState !== STATES.PENDING) {
      return;
    }
    // 1- initialize metrics
    const loadPackageTrackingDetailsMetricsPublisher =
      initialMetricsPublisher.newChildActionPublisherForMethod('LoadPackageTrackingDetails');
    const loadPackageTrackingDetailsHandlerMetric = new KatalMetrics.Metric.TimedAttempt(
      'PageLoad'
    ).withMonitor();
    loadPackageTrackingDetailsMetricsPublisher.publish(
      new KatalMetrics.Metric.String('trackingId', this._trackingNumber)
    );
    try {
      // 2- fetch package details from backend API
      if (this._isShipped) {
        const packageTrackingDetail: AxiosResponse<any> = yield getPackageTrackingDetails(
          this._trackingNumber,
          this._shipMethod
        );
        this.packageTrackingDetail = packageTrackingDetail;

        if (
          !packageTrackingDetail.data.trackingEvents ||
          packageTrackingDetail.data.trackingEvents.length === 0
        ) {
          runInAction(() => {
            this._loadingState = STATES.ERROR;
          });
          loadPackageTrackingDetailsHandlerMetric.setFailure();
          loadPackageTrackingDetailsMetricsPublisher.publishCounterMonitor(
            'NullOrEmptyTrackingEvents',
            1
          );
          return;
        }

        loadPackageTrackingDetailsMetricsPublisher.publishCounterMonitor(
          'NullOrEmptyTrackingEvents',
          0
        );

        // 3- process the data
        runInAction(() => {
          if (packageTrackingDetail.data.estimatedArrivalDate != null) {
            this._estimatedArrivalDate = parseISOString(
              packageTrackingDetail.data.estimatedArrivalDate
            );
          }

          this._transitState = packageTrackingDetail.data.transitState;
          packageTrackingDetail.data.trackingEvents.forEach((trackingEvent: any) => {
            const event = new TrackingEvent();
            event.setEventAddress(trackingEvent.eventAddress);
            event.setEventDate(parseISOString(trackingEvent.eventDate));
            event.setEventDescription(trackingEvent.eventDescription);
            this._trackingDetail.addTrackingEvent(event);
          });

          // Set delivery details if available, regardless of transit state
          if (packageTrackingDetail.data.deliveryDetails !== null) {
            const deliveryDetails = packageTrackingDetail.data.deliveryDetails;
            const packageDeliveryDetails: DeliveryDetails = {
              packageDropOffLocation: deliveryDetails?.packageDropOffLocation,
              lockerDetails: {
                lockerNumber: deliveryDetails?.lockerDetails?.lockerNumber,
                lockerAccessCode: deliveryDetails?.lockerDetails?.lockerAccessCode,
              },
              deliveryWindow: {
                startDate: deliveryDetails?.deliveryWindow?.startDate,
                endDate: deliveryDetails?.deliveryWindow?.endDate,
              },
            };
            this._deliveryDetails = packageDeliveryDetails;
          }

          if (this._transitState === 'DELIVERED') {
            this._hasAdditionalDeliveryDetails =
              packageTrackingDetail?.data?.hasAdditionalDeliveryDetails;
          }
        });
      } else if (this._scannedDate) {
        runInAction(() => {
          this._transitState = 'AWAITING_PICKUP_BY_CARRIER';
          const event = new TrackingEvent();
          event.setEventDate(this._scannedDate);
          event.setEventDescription('swiship_package_is_awaiting_pickup');
          this._trackingDetail.addTrackingEvent(event);
        });
      }
      runInAction(() => {
        this._loadingState = STATES.DONE;
      });
      loadPackageTrackingDetailsHandlerMetric.setSuccess();
    } catch (error) {
      PackageInfo.handleErrors(
        this,
        error,
        loadPackageTrackingDetailsHandlerMetric,
        this._trackingNumber
      );
    } finally {
      loadPackageTrackingDetailsMetricsPublisher.publish(loadPackageTrackingDetailsHandlerMetric);
    }
  });
}
