import React, { Suspense } from 'react';
import SearchBox from './SearchBox';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import AppLayout from '@amzn/meridian/app-layout';
import Masthead from '@amzn/meridian/masthead';
import Theme from '@amzn/meridian/theme';
import { observer } from 'mobx-react';
import './SearchPackagesPage.scss';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';
import HeaderComponent from 'src/components/HeaderComponent';

const SEARCH_PAGE_WIDTH = 480;

// @ts-expect-error TODO error type not defined
export const SearchPackagesContent = observer(({ error }) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  return (
    <Row width="100%" alignmentHorizontal="center" className="searchPackagesContent">
      <Row width={SEARCH_PAGE_WIDTH} alignmentHorizontal="center" spacingInset="300">
        <Column alignmentHorizontal="start" spacing="none">
          <Text type="h600">{t(bundle, 'swiship_track_your_packages')}</Text>
          <Text type="b300">{t(bundle, 'swiship_enter_a_tracking_number_to_get_started')}</Text>
          <Text type="b200">&nbsp;</Text>
          <Suspense fallback={<Loader type="circular" />}>
            <SearchBox />
          </Suspense>
          &nbsp;
          {error}
        </Column>
      </Row>
    </Row>
  );
});

export class SearchPackagesPage extends React.Component {
  render(): React.ReactNode {
    return (
      <AppLayout headerComponent={Masthead} backgroundColor="secondary">
        <Theme tokens={{ mastheadSpacingHorizontal: '0px' }}>
          <Masthead children={<HeaderComponent />} />
        </Theme>
        <SearchPackagesContent error={null} />
      </AppLayout>
    );
  }
}

export default SearchPackagesPage;
