import moment from 'moment-timezone';

export interface TimezoneInfo<T extends string | undefined = string> {
  shortTZ: T;
  fullTZ: T;
}

export const sleep = (ms: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isSameDay = (day1: Date | undefined, day2: Date | undefined): boolean => {
  if (!day1 || !day2) return false;
  return (
    day1.getDate() === day2.getDate() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getFullYear() === day2.getFullYear()
  );
};

export const parseISOString = (ISODate: string): Date => {
  return new Date(ISODate.replace(/\s+/g, ''));
};

export const isWithin120Days = (estimatedDelivery: Date): boolean => {
  const currentDate = new Date();
  return Math.abs(estimatedDelivery.getTime() - currentDate.getTime()) <= 10368000000;
};

export const formatEventTime = (dateString: string): TimezoneInfo => {
  const date: Date = new Date(dateString);
  const guessedZone: string = moment.tz?.guess();
  const zonedDate = moment(date).tz(guessedZone);

  // Get short timezone name
  const shortTZ: string = zonedDate.format('z');

  // Get full timezone name
  const fullTZ: string =
    new Intl.DateTimeFormat(moment.locale(), {
      timeZone: guessedZone,
      timeZoneName: 'long',
    })
      .formatToParts(date)
      .find((part) => part.type === 'timeZoneName')?.value || '';

  return { shortTZ, fullTZ };
};
