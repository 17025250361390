import React from 'react';
import { observer } from 'mobx-react';
import HeaderComponent from 'src/components/HeaderComponent';
import Masthead from '@amzn/meridian/masthead';
import Theme from '@amzn/meridian/theme';
import AppLayout from '@amzn/meridian/app-layout';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Card from '@amzn/meridian/card';
import { RECYCLE_PAGE_ASSETS } from 'src/components/RecyclePage/RecyclePageConstants';
import { useIsMobileView } from 'src/components/Responsive/WindowDimensions';
import Box from '@amzn/meridian/box';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';
import { publishSingleMetricCount } from 'src/metrics';
import './recyclePage.scss';

export const RecyclePageContent = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const mobileView = useIsMobileView();
  const cardComponents = mobileView
    ? RECYCLE_PAGE_ASSETS.map(function (asset) {
        return (
          <Card border-radius="4px" spacingInset="none" key={asset.title}>
            <Row
              className="cardRow"
              width="100%"
              widths={['grid-5', 'grid-7']}
              spacingInset="200"
              alignmentVertical="stretch"
              spacing="none"
            >
              <Column height="100%" alignmentHorizontal="stretch" spacingInset="200" spacing="200">
                <img alt="Image" src={asset.image} width="100%" height="100%" />
                <Text type="h200">{t(bundle, asset.title)}</Text>
              </Column>
              <Column height="100%" alignmentHorizontal="stretch" spacingInset="200" spacing="200">
                <Text type="b100">{t(bundle, asset.description)}</Text>
              </Column>
            </Row>
          </Card>
        );
      })
    : RECYCLE_PAGE_ASSETS.map(function (asset) {
        return (
          <Card width="100%" border-radius="4px" spacingInset="none" key={asset.title}>
            <Row
              className="cardRow"
              width="100%"
              widths={['grid-5', 'grid-7']}
              spacingInset="200"
              alignmentVertical="stretch"
              spacing="none"
            >
              <Column height="100%" alignmentHorizontal="stretch" spacingInset="200" spacing="200">
                <img alt="Image" src={asset.image} width="100%" height="100%" />
              </Column>
              <Column height="100%" alignmentHorizontal="stretch" spacingInset="200" spacing="200">
                <Text type="h200">{t(bundle, asset.title)}</Text>
                <Text type="b100">{t(bundle, asset.description)}</Text>
              </Column>
            </Row>
          </Card>
        );
      });
  return mobileView ? (
    <Box id="recyclePageContent" spacingInset="none">
      <Row width="100%" spacingInset="200" alignmentVertical="stretch">
        <Column spacingInset="200">{cardComponents}</Column>
      </Row>
    </Box>
  ) : (
    <Box id="recyclePageContent" data-testid="recyclePageContent" spacingInset="200">
      <Row
        width="100%"
        widths={[
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
          'grid-4',
        ]}
        maxWidth="1440px"
        spacingInset="200"
        wrap="down"
      >
        {cardComponents.map((card, index) => {
          return (
            <Column height="25%" heights="fill" key={'card-' + index}>
              {card}
            </Column>
          );
        })}
      </Row>
    </Box>
  );
});

export const RecyclePage = observer(() => {
  publishSingleMetricCount('RouteToRecyclePage');
  return (
    <AppLayout headerComponent={Masthead} backgroundColor="secondary">
      <Theme tokens={{ mastheadSpacingHorizontal: '0px' }}>
        <Masthead children={<HeaderComponent />} />
      </Theme>
      <RecyclePageContent />
    </AppLayout>
  );
});
export default RecyclePage;
