import React from 'react';
import { observer } from 'mobx-react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import { TrackingEvent } from 'src/stores/TrackingEvent';
import Box from '@amzn/meridian/box';
import { italicize } from 'src/utils/Formatters';
import TrackingEventComponent from 'src/components/TrackPage/TrackingEventComponent';
import moment from 'moment';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

const getDateHeader = (date: Date): any => {
  return <Text type="b300">{moment(date).format('dddd, MMMM Do')}</Text>;
};

/**
 * This function takes sameDayEvents array and return 'Grouped Events' based on date
 * The returned events are react Element which can be used to render.
 * Assumes that the input tracking events are sorted based on date.
 * @param sameDayEvents
 */
const GroupedEvent = observer(({ sameDayEvents }: any) => {
  /** We need translation here to listen to locale changes to localize date */
  if (!sameDayEvents || sameDayEvents.length === 0) {
    return null;
  }
  return (
    <Column heights={['fit']}>
      <Box />
      {getDateHeader(sameDayEvents[0].getEventDate)}
      <Column>
        {sameDayEvents.map((sameDayEvent: TrackingEvent, index: number) => {
          return (
            <Row key={index}>
              <TrackingEventComponent trackingEvent={sameDayEvent} />
            </Row>
          );
        })}
      </Column>
    </Column>
  );
});

/**
 * Tracking History Component
 * For eg: input trackingEvents:  [[{eventDate: 10 Feb 10:10AM, eventAddress: Tempe,AZ},
 *                                  {eventDate: 10 Feb 9:10AM, eventAddress: Garden Grove, CA},
 *                                  {eventDate: 10 Feb 8:10AM, eventAddress: Garden Grove, CA}],
 *                                 [{eventDate: 9 Feb 10:10AM, eventAddress: Garden Grove, CA}]]
 *         output: https://w.amazon.com/bin/view/SFS/MCF/OmniCat/Projects/McfTrackingWebsite/UIDesign/#HTrackingHistory
 */
export const TrackingHistory = observer(({ trackingEvents }: any) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  if (!trackingEvents || trackingEvents.length === 0) {
    return null;
  }
  return (
    <Column>
      {trackingEvents.map((sameDayEvents: TrackingEvent[], index: number) => {
        return (
          <Row key={index}>
            <GroupedEvent sameDayEvents={sameDayEvents} />{' '}
          </Row>
        );
      })}
      {italicize(<Text type="b200">{t(bundle, 'swiship_times_local_timezone')}</Text>)}
    </Column>
  );
});

export default TrackingHistory;
