import { makeObservable, observable, action, computed } from 'mobx';
import { DATE_AND_TIME } from 'src/utils/Formatters';
import { TrackingEvent } from 'src/stores/TrackingEvent';
import { isSameDay } from 'src/utils/Timer';

export class TrackingDetail {
  @observable protected _transitState = '';
  @observable protected _estimatedArrivalDate: Date = new Date();
  @observable protected _trackingEvents: TrackingEvent[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setTransitState(transitState: string): void {
    this._transitState = transitState;
  }

  get getTransitState(): string {
    return this._transitState;
  }

  @action
  setEstimateArrivalDate(estimatedArrivalDate: Date): void {
    this._estimatedArrivalDate = estimatedArrivalDate;
  }

  get getEstimatedArrivalDate(): string {
    return DATE_AND_TIME.format(this._estimatedArrivalDate);
  }

  /**
   * Given current TrackingEvents array and a given Tracking Event to insert,
   * Determines the place to insert the given Tracking Event based on event Date.
   * Uses binary search for determining the position
   * @param trackingEvents
   * @param eventToInsert
   */
  private placeToInsert(trackingEvents: TrackingEvent[], eventToInsert: TrackingEvent): number {
    let low = 0;
    let high = trackingEvents.length;

    while (low < high) {
      const mid = (low + high) >>> 1;
      const midDate = trackingEvents[mid].getEventDate;
      const insertDate = eventToInsert.getEventDate;

      if (!midDate || !insertDate) {
        // Handle undefined dates
        if (!midDate && !insertDate) {
          return mid; // Both dates are undefined, insert at current position
        }
        if (!midDate) {
          low = mid + 1; // midDate is undefined, move to right half
        } else {
          high = mid; // insertDate is undefined, move to left half
        }
      } else if (midDate < insertDate) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return low;
  }

  /**
   * Use this function to add tracking events,
   * This function make sure that the tracking events are added sequentially based on date
   * @param trackingEvent
   */
  @action
  addTrackingEvent(trackingEvent: TrackingEvent): void {
    const index = this.placeToInsert(this._trackingEvents, trackingEvent);
    this._trackingEvents.splice(this._trackingEvents.length - index, 0, trackingEvent);
  }

  /**
   * This function takes TrackingEvents array and returns 'Grouped Events' based on date
   * Assumes that the input tracking events are sorted based on date.
   * This is a 2-pointer approach which runs in O(n) time.
   * For eg: input:  [
   *                    {eventDate: 10 Feb 10:10AM, eventAddress: Tempe,AZ},
   *                    {eventDate: 10 Feb 9:10AM, eventAddress: Garden Grove, CA},
   *                    {eventDate: 10 Feb 8:10AM, eventAddress: Garden Grove, CA},
   *                    {eventDate: 9 Feb 10:10AM, eventAddress: Garden Grove, CA}
   *                 ]
   *         output: [
   *                    [
   *                       {eventDate: 10 Feb 10:10AM, eventAddress: Tempe,AZ},
   *                       {eventDate: 10 Feb 9:10AM, eventAddress: Garden Grove, CA},
   *                       {eventDate: 10 Feb 8:10AM, eventAddress: Garden Grove, CA}
   *                    ],
   *                    [
   *                       {eventDate: 9 Feb 10:10AM, eventAddress: Garden Grove, CA}
   *                    ]
   *                 ]
   */
  @computed
  get getGroupedTrackingEventsByDay(): TrackingEvent[][] {
    const events = this._trackingEvents;
    const groupedEvents: TrackingEvent[][] = [];
    for (let slowPointer = 0; slowPointer < events.length; ) {
      const sameDayEvents: TrackingEvent[] = [];
      sameDayEvents.push(events[slowPointer]);
      let leadPointer = slowPointer + 1;
      while (
        leadPointer < events.length &&
        isSameDay(events[slowPointer].getEventDate, events[leadPointer].getEventDate)
      ) {
        sameDayEvents.push(events[leadPointer]);
        leadPointer++;
      }
      groupedEvents.push(sameDayEvents);
      slowPointer = leadPointer;
    }
    return groupedEvents;
  }

  get getAllEvents(): TrackingEvent[] {
    return this._trackingEvents;
  }
}
