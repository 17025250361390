import React from 'react';
import { observer } from 'mobx-react';
import Text from '@amzn/meridian/text';
import ProgressTracker, { ProgressTrackerStep } from '@amzn/meridian/progress-tracker';
import Column from '@amzn/meridian/column';
import { PROGRESS_TRACKER_FIELDS } from 'src/utils/Constants';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';

export const Tracker = observer(({ transitState }: any) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const fields = PROGRESS_TRACKER_FIELDS[transitState];

  if (fields) {
    return (
      <Column heights={'fit'} alignmentHorizontal="center" alignmentVertical="top" spacing="300">
        <ProgressTracker type="theme" direction="row" compact={false}>
          <ProgressTrackerStep
            type={fields['trackerStep1Type']}
            label={t(bundle, fields['trackerStep1TextId'])}
            alert={fields['trackerAlert1Type']}
          />
          <ProgressTrackerStep
            type={fields['trackerStep2Type']}
            label={t(bundle, fields['trackerStep2TextId'])}
            alert={fields['trackerAlert2Type']}
          />
          <ProgressTrackerStep
            type={fields['trackerStep3Type']}
            label={t(bundle, fields['trackerStep3TextId'])}
            alert={fields['trackerAlert3Type']}
          />
        </ProgressTracker>
      </Column>
    );
  } else {
    return (
      <Column heights={'fit'} alignmentHorizontal="center" alignmentVertical="top" spacing="300">
        <Text alignment="center" type="h300" color={'error'}>
          {t(bundle, 'swiship_unknown_delivery_state')}
        </Text>
      </Column>
    );
  }
});

export default Tracker;
