export enum Region {
  NA = 'NA',
  EU = 'EU',
  FE = 'FE',
}

export interface DomainInfo {
  region: Region;
}

export function getDomainRegion(domain: string): Region {
  domain = domain.toLowerCase();

  if (domain.endsWith('.ca') || domain.includes('-ca.') || domain.includes('-ca-')) {
    return Region.NA;
  }

  if (
    domain.endsWith('.co.uk') ||
    domain.endsWith('.de') ||
    domain.endsWith('.es') ||
    domain.endsWith('.fr') ||
    domain.endsWith('.it') ||
    domain.endsWith('.in') ||
    domain.includes('-in') ||
    domain.includes('-co-uk') ||
    domain.includes('-es') ||
    domain.includes('-de') ||
    domain.includes('-fr') ||
    domain.includes('-it')
  ) {
    return Region.EU;
  }

  if (
    domain.endsWith('.jp') ||
    domain.endsWith('.com.au') ||
    domain.includes('-jp') ||
    domain.includes('-au')
  ) {
    return Region.FE;
  }

  // Default to NA if no match is found
  return Region.NA;
}

export function getDomainInfo(domain: string): DomainInfo {
  return { region: getDomainRegion(domain) };
}
