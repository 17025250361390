import React from 'react';
import { MbmProvider } from '@amzn/react-arb-tools';
import arbManifest from '../i18n/translations/arbManifest';

import { getMbmOptions } from '../helpers';
import { DiscoveryRouter } from 'src/router/DiscoveryRouter';
import { BrowserRouter } from 'react-router-dom';

const mbmOptions = getMbmOptions(arbManifest);

const App = (): React.ReactElement => {
  return (
    <MbmProvider {...mbmOptions}>
      <BrowserRouter>
        <DiscoveryRouter />
      </BrowserRouter>
    </MbmProvider>
  );
};

export default App;
