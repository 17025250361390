import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';

const locales = [
  {
    name: 'English',
    value: 'en-US',
  },
  {
    name: 'Español',
    value: 'es-ES',
  },
  {
    name: 'Italiano',
    value: 'it-IT',
  },
  {
    name: 'Français',
    value: 'fr-FR',
  },
  {
    name: 'Deutsch',
    value: 'de-DE',
  },
  {
    name: '日本語',
    value: 'ja-JP',
  },
  {
    name: '中文',
    value: 'zh-CN',
  },
  {
    name: '한국어',
    value: 'ko-KR',
  },
];

// MarketPlaces
const USAmazon = 'USAmazon';
const FEAmazon = 'FEAmazon';
const EUAmazon = 'EUAmazon';

// Domains
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOCALHOST = 'localhost';
const CANADA_DOMAIN = 'swiship.ca';
const US_DOMAIN = 'swiship.com';
const AUSTRALIA_DOMAIN = 'swiship.com.au';
const JAPAN_DOMAIN = 'swiship.jp';
const UK_DOMAIN = 'swiship.co.uk';
const GERMANY_DOMAIN = 'swiship.de';
const SPAIN_DOMAIN = 'swiship.es';
const FRANCE_DOMAIN = 'swiship.fr';
const ITALY_DOMAIN = 'swiship.it';

const DOMAIN_TO_LOCALE_MAP: Record<string, string> = {
  localhost: 'en-US',
  'swiship.ca': 'en-US',
  'swiship.com': 'en-US',
  'swiship.com.au': 'en-US',
  'swiship.co.uk': 'en-US',
  'swiship.de': 'de-DE',
  'swiship.es': 'es-ES',
  'swiship.fr': 'fr-FR',
  'swiship.it': 'it-IT',
  'swiship.jp': 'ja-JP',
};

const stripWwwFromDomain = (domain: string): string => {
  return domain.replace('www.', '');
};

export const getLocaleFromDomain = (domain: string): string => {
  domain = stripWwwFromDomain(domain);
  return DOMAIN_TO_LOCALE_MAP[domain];
};

export const getRealm = (domain: string): string => {
  domain = stripWwwFromDomain(domain);
  if ([CANADA_DOMAIN, US_DOMAIN].includes(domain)) {
    return USAmazon;
  } else if ([AUSTRALIA_DOMAIN, JAPAN_DOMAIN].includes(domain)) {
    return FEAmazon;
  } else if (
    [UK_DOMAIN, GERMANY_DOMAIN, SPAIN_DOMAIN, FRANCE_DOMAIN, ITALY_DOMAIN].includes(domain)
  ) {
    return EUAmazon;
  } else {
    return USAmazon;
  }
};

export default locales;
