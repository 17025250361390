import { makeObservable, observable, action, flow, runInAction } from 'mobx';
import { createContext } from 'react';
import { PACKAGES_COUNT_PER_PAGE, STATES } from 'src/utils/Constants';
import { parseISOString } from 'src/utils/Timer';
import { PackageInfo } from 'src/stores/PackageInfo';
import { getAllPackageInfos } from 'src/utils/api';
import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricTimedAttempt from '@amzn/katal-metrics/lib/metricObject/KatalMetricTimedAttempt';
import { logger } from 'src/logger';
import { AxiosResponse } from 'axios';

export type LogoImageSourceType = 'NHS_FBA_LOGO' | 'DEFAULT_SWISHIP_LOGO' | '';

export class OrderInfo {
  @observable protected _loadingState: string = STATES.DONE;
  @observable protected _orderId = '';
  @observable protected _packageInfos: PackageInfo[] = [];
  @observable protected _packageIndex = 0;
  @observable protected _logoImageSource: LogoImageSourceType = '';
  @observable protected _unprocessedItemCount = 0;
  @observable protected _isAssistEnabled = false;

  constructor() {
    makeObservable(this);
  }

  get getPackageIndex(): number {
    return this._packageIndex;
  }

  @action
  setPackageIndex(index: number): void {
    this._packageIndex = index;
  }

  get getCurrentPackage(): PackageInfo {
    return this._packageInfos[this._packageIndex];
  }

  @action
  setLoadingState(loadingState: string): void {
    this._loadingState = loadingState;
  }

  get getLoadingState(): string {
    return this._loadingState;
  }

  @action
  setOrderId(orderId: string): void {
    this._orderId = orderId;
  }

  get getOrderId(): string {
    return this._orderId;
  }

  @action
  addPackageInfo(packageInfo: PackageInfo): void {
    this._packageInfos.push(packageInfo);
  }

  get getPackageInfos(): PackageInfo[] {
    return this._packageInfos;
  }

  get getLogoImageSource(): LogoImageSourceType {
    return this._logoImageSource;
  }

  @action
  setLogoImageSource(logoImageSource: LogoImageSourceType): void {
    this._logoImageSource = logoImageSource;
  }

  get getUnprocessedItemCount(): number {
    return this._unprocessedItemCount;
  }

  @action
  setUnprocessedItemCount(unprocessedItemCount: number): void {
    this._unprocessedItemCount = unprocessedItemCount;
  }

  @action
  setIsAssistEnabled(isAssistEnabled: boolean): void {
    this._isAssistEnabled = isAssistEnabled;
  }

  get getIsAssistEnabled(): boolean {
    return this._isAssistEnabled;
  }

  private static handleErrors = (
    orderInfo: OrderInfo,
    error: any,
    metric: KatalMetricTimedAttempt,
    trackingId: string
  ): any => {
    if (error.response && error.response.status >= 400 && error.response.status < 500) {
      orderInfo.setLoadingState(STATES.UNKNOWN_TRACKING_NUMBER);
      metric.setSuccess();
      logger.info('Invalid tracking id:' + trackingId + ' with error:' + error);
      return;
    }
    orderInfo.setLoadingState(STATES.ERROR);
    metric.setFailure();
    logger.error(
      'Error fetching package info for tracking id:' + trackingId + ' with error:' + error
    );
  };

  fetchAllPackageInfos = flow(function* (this: OrderInfo, trackingId: string) {
    this.setLoadingState(STATES.PENDING);
    this._packageInfos = [];
    this._packageIndex = 0;
    // 1- initialize metrics
    const fetchAllPackagesAndLoadTrackingDetailsMetricsPublisher =
      initialMetricsPublisher.newChildActionPublisherForMethod(
        'FetchAllPackagesAndLoadTrackingDetails'
      );
    const fetchAllPackagesAndLoadTrackingDetailsHandlerMetric =
      new KatalMetrics.Metric.TimedAttempt('PageLoad').withMonitor();

    fetchAllPackagesAndLoadTrackingDetailsMetricsPublisher.publish(
      new KatalMetrics.Metric.String('trackingId', trackingId)
    );

    try {
      // 2- fetch all package infos.
      const allPackageInfos: AxiosResponse<any> = yield getAllPackageInfos(trackingId);

      runInAction(() => {
        this._logoImageSource = allPackageInfos.data.logoImageSource;
        this._isAssistEnabled = allPackageInfos.data.assistEnabled;
      });

      if (
        !allPackageInfos.data.packageInfoList ||
        allPackageInfos.data.packageInfoList.length === 0
      ) {
        /** No tracking data found for this tracking number */
        this.setLoadingState(STATES.ERROR);
        fetchAllPackagesAndLoadTrackingDetailsHandlerMetric.setFailure();
        fetchAllPackagesAndLoadTrackingDetailsMetricsPublisher.publishCounterMonitor(
          'NullOrEmptyPackageInfo',
          1
        );
        return;
      }

      fetchAllPackagesAndLoadTrackingDetailsMetricsPublisher.publishCounterMonitor(
        'NullOrEmptyPackageInfo',
        0
      );

      // 3- process the backend data
      runInAction(() => {
        allPackageInfos.data.packageInfoList.forEach((serverPackageInfo: any) => {
          const pInfo = new PackageInfo();
          pInfo.setDisplayableCarrierName(serverPackageInfo.displayableCarrierName);
          pInfo.setEstimateArrivalDate(parseISOString(serverPackageInfo.estimatedArrivalDate));
          pInfo.setShipMethod(serverPackageInfo.shipMethod);
          pInfo.setTrackingNumber(serverPackageInfo.trackingNumber);
          pInfo.setItemCount(serverPackageInfo.itemCount);
          pInfo.setIsShipped(serverPackageInfo.isShipped);
          pInfo.setScannedDate(
            serverPackageInfo.scannedDate
              ? parseISOString(serverPackageInfo.scannedDate)
              : undefined
          );
          this.addPackageInfo(pInfo);
        });
        this._unprocessedItemCount = allPackageInfos.data.unprocessedItemCount
          ? allPackageInfos.data.unprocessedItemCount
          : 0;
        this.setLoadingState(STATES.DONE);
      });

      // 4- fetch package tracking details that will be displayed on first paginated page
      const fetchCount =
        this._packageInfos.length < PACKAGES_COUNT_PER_PAGE
          ? this._packageInfos.length
          : PACKAGES_COUNT_PER_PAGE;
      for (let index = 0; index < fetchCount; ++index) {
        /** yield here, because we are waiting for the tracking details to load **/
        yield this._packageInfos[index].fetchPackageTrackingDetails();
      }
      fetchAllPackagesAndLoadTrackingDetailsHandlerMetric.setSuccess();
    } catch (error) {
      runInAction(() => {
        this._logoImageSource = 'DEFAULT_SWISHIP_LOGO';
      });
      OrderInfo.handleErrors(
        this,
        error,
        fetchAllPackagesAndLoadTrackingDetailsHandlerMetric,
        trackingId
      );
    } finally {
      fetchAllPackagesAndLoadTrackingDetailsMetricsPublisher.publish(
        fetchAllPackagesAndLoadTrackingDetailsHandlerMetric
      );
    }
  });
}

export default createContext(new OrderInfo());
