import React from 'react';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';

export const UnknownTrackingNumber = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  return (
    <div id={'unknownTrackingNumberErrorMessage'}>
      <Column alignmentVertical={'center'} alignmentHorizontal={'center'} spacing={'300'}>
        <Text color={'error'}>{t(bundle, 'swiship_unknown_tracking_number_error_message')}</Text>
      </Column>
    </div>
  );
});

export const OldTrackingNumber = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  return (
    <div id={'oldTrackingNumberErrorMessage'}>
      <Column alignmentVertical={'center'} alignmentHorizontal={'center'} spacing={'medium'}>
        <Text color={'error'}>{t(bundle, 'swiship_old_tracking_number_error_message')}</Text>
      </Column>
    </div>
  );
});

export default UnknownTrackingNumber;
