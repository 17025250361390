import { makeObservable, observable, action } from 'mobx';

export interface ProofOfDeliveryImage {
  imageType: string;
  imageUrl: string;
}

export class ProofOfDeliveryDetails {
  @observable protected _proofOfDeliveryDetails: ProofOfDeliveryImage[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setProofOfDeliveryDetails(details: ProofOfDeliveryImage[]): void {
    this._proofOfDeliveryDetails = details;
  }

  get getProofOfDeliveryDetails(): ProofOfDeliveryImage[] {
    return this._proofOfDeliveryDetails;
  }
}
