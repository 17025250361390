import React from 'react';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { logger } from 'src/logger';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { getTranslationFromBundle, t } from 'src/helpers';

export const PackageLoadingStatusComponent = observer(({ packageInfo }: any) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

  if (!packageInfo) {
    logger.warn('PackageStatusComponent: package information are missing');
    return null;
  }

  const itemsCount = packageInfo.getItemCount;
  const itemsHeader =
    itemsCount == null
      ? t(bundle, 'swiship_package_capitalized')
      : getTranslationFromBundle(
          bundle,
          ['swiship_order_item', 'swiship_order_items'],
          { items: itemsCount.toString() },
          itemsCount
        );

  return (
    <Column spacing="none">
      <Row spacing="none">
        <Text type="h400">{itemsHeader}</Text>
      </Row>
    </Column>
  );
});

export default PackageLoadingStatusComponent;
