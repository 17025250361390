import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import TrackingHistoryComponent from 'src/components/TrackPackagesPage/TrackingHistoryComponent';
import { getExternalTrackingLink, PAGE_CONTENT_WIDTH, STATES } from 'src/utils/Constants';
import Box from '@amzn/meridian/box';
import PackageStatusComponent from 'src/components/TrackPackagesPage/PackageStatusComponent';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import OrderInfoContext from 'src/stores/OrderInfo';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import chevronRightSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-right-small';
import Card from '@amzn/meridian/card';
import Button from '@amzn/meridian/button';
import { PackageTrackingVisibility } from 'src/components/TrackPackagesPage/TrackingContent';
import { useContentWidth, useIsMobileView } from 'src/components/Responsive/WindowDimensions';
import DesktopView from 'src/components/Responsive/DesktopView';
import PackageLoadingStatusComponent from 'src/components/TrackPackagesPage/PackageLoadingStatusComponent';
import Loader from '@amzn/meridian/loader';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import './PackageComponent.scss';
import { PackageInfo } from 'src/stores/PackageInfo';
import DeliveryWindowComponent from 'src/components/TrackPackagesPage/DeliveryWindowComponent';
import { logger } from 'src/logger';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';
import { MessageBundle } from '@amzn/arb-tools';

const getExternalTrackingLinkComponent = (carrierName: string, trackingNumber: string): any => {
  const externalTrackingLink = getExternalTrackingLink(carrierName, trackingNumber);

  if (externalTrackingLink) {
    return (
      <Text type="b300">
        <Link href={externalTrackingLink} type="primary" target="_blank" rel="noopener noreferrer">
          {trackingNumber}
        </Link>
      </Text>
    );
  } else {
    return <Text type="b300">{trackingNumber}</Text>;
  }
};

const getTrackingInformationComponent = (
  packageInfo: PackageInfo,
  isMobile: boolean,
  bundle: MessageBundle
): any => {
  const responsiveClassName =
    'trackingInformation trackingInformationPadding ' +
    (isMobile ? 'trackingInformationMobile' : 'trackingInformationDesktop');
  const responsiveWidth = isMobile ? 'auto' : '85%';
  logger.info(
    'getTrackingInformationComponent loaded for tracking id: ' +
      packageInfo?.getTrackingNumber +
      ' with status: ' +
      packageInfo?.getTransitState
  );
  return (
    <Row alignmentVertical="top">
      <Table className={responsiveClassName} layout="auto">
        <TableRow>
          <TableCell alignmentVertical="top">
            <Text type="h100">{t(bundle, 'texttrack_mobile_carrier_69019')}</Text>
          </TableCell>
          <TableCell alignmentVertical="top" width={responsiveWidth}>
            <Text type="b300">{packageInfo.getDisplayableCarrierName}</Text>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell alignmentVertical="top">
            <Text type="h100">{t(bundle, 'shiptrack_ng_label_tracking')}</Text>
          </TableCell>
          <TableCell alignmentVertical="top">
            {getExternalTrackingLinkComponent(
              packageInfo.getDisplayableCarrierName,
              packageInfo.getTrackingNumber
            )}
          </TableCell>
        </TableRow>
        {packageInfo.getTransitState !== 'DELIVERED' &&
          packageInfo.getDeliveryDetails?.deliveryWindow?.startDate &&
          packageInfo.getDeliveryDetails?.deliveryWindow?.endDate && (
            <TableRow>
              <TableCell alignmentVertical="top">
                <Text type="h100">{t(bundle, 'swiship_order_delivery_window')}</Text>
              </TableCell>
              <TableCell alignmentVertical="top">
                <DeliveryWindowComponent
                  deliveryWindow={packageInfo.getDeliveryDetails.deliveryWindow}
                />
              </TableCell>
            </TableRow>
          )}
        {packageInfo?.getDeliveryDetails?.packageDropOffLocation && (
          <TableRow>
            <TableCell alignmentVertical="top">
              <Text type="h100">{t(bundle, 'swiship_dropoff_location_label')}</Text>
            </TableCell>
            <TableCell alignmentVertical="top">
              <Text type="b300">
                {t(bundle, packageInfo.getDeliveryDetails?.packageDropOffLocation)}
              </Text>
            </TableCell>
          </TableRow>
        )}
      </Table>
      {packageInfo.getDeliveryDetails && (
        <Table className={responsiveClassName} layout="auto">
          {packageInfo.getDeliveryDetails?.lockerDetails?.lockerNumber && (
            <TableRow>
              <TableCell alignmentVertical="top" alignmentHorizontal="end">
                <Text type="h100">{t(bundle, 'swiship_order_delivery_box_number')}</Text>
              </TableCell>
              <TableCell alignmentVertical="top">
                {packageInfo.getDeliveryDetails?.lockerDetails?.lockerNumber}
              </TableCell>
            </TableRow>
          )}
          {packageInfo.getDeliveryDetails?.lockerDetails?.lockerAccessCode && (
            <TableRow>
              <TableCell alignmentVertical="top" alignmentHorizontal="end">
                <Text type="h100">{t(bundle, 'swiship_order_locker_code')}</Text>
              </TableCell>
              <TableCell alignmentVertical="top">
                {packageInfo.getDeliveryDetails?.lockerDetails?.lockerAccessCode}
              </TableCell>
            </TableRow>
          )}
        </Table>
      )}
    </Row>
  );
};

export const PackageComponent = observer(
  // @ts-expect-error TODO to update
  ({ packageInfo, packageVisibility, setPackageVisibility, index }) => {
    if (!packageInfo) {
      return null;
    }

    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    const orderInfo = useContext(OrderInfoContext);
    const isPackagePending = orderInfo.getPackageInfos[index].getLoadingState === STATES.PENDING;

    const isDetailsExpanded =
      packageVisibility[index] === PackageTrackingVisibility.ShortTracking ||
      packageVisibility[index] === PackageTrackingVisibility.FullTracking;
    const isLoadMoreExpanded = packageVisibility[index] === PackageTrackingVisibility.FullTracking;

    const chevronTokens = isDetailsExpanded ? chevronDownSmallTokens : chevronRightSmallTokens;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function changePackageVisibility(newVisibility: PackageTrackingVisibility, reload = true) {
      packageVisibility[index] = newVisibility;
      if (reload) {
        //slice() will create copy/new instance of packageVisibility - needed for PackageComponent to refresh
        setPackageVisibility(packageVisibility.slice());
      }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function loadMore() {
      changePackageVisibility(PackageTrackingVisibility.FullTracking);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function onExpandDetailsClick() {
      const shouldHideDetails = packageVisibility[index] !== PackageTrackingVisibility.NoTracking;
      if (shouldHideDetails) {
        changePackageVisibility(PackageTrackingVisibility.NoTracking);
      } else {
        changePackageVisibility(PackageTrackingVisibility.ShortTracking, true);
      }
    }

    const trackingHistoryComponent = (
      <TrackingHistoryComponent
        trackingEvents={packageInfo.getTrackingDetail.getGroupedTrackingEventsByDay}
        isLoadMoreExpanded={isLoadMoreExpanded}
        loadMore={loadMore}
        hasAdditionalDeliveryDetails={packageInfo.getAdditionalDeliveryDetails}
        trackingNumber={packageInfo.getTrackingNumber}
      />
    );

    const spacing = useContentWidth() < PAGE_CONTENT_WIDTH ? '300' : '500';

    const isMobile = useIsMobileView();

    if (isPackagePending) {
      return (
        <Card spacingInset={spacing} width={useContentWidth()}>
          <Row widths={['80%', '20%']}>
            <Column>
              <PackageLoadingStatusComponent packageInfo={packageInfo} />
            </Column>
            <Column alignmentVertical={'bottom'} alignmentHorizontal={'end'} spacing="400">
              <Loader size={'medium'} type={'circular'} />
            </Column>
          </Row>
        </Card>
      );
    } else
      return (
        <Card
          spacingInset={spacing}
          width={useContentWidth()}
          minWidth={isMobile ? undefined : '850px'}
        >
          <Row widths={['80%', '20%']}>
            <Column>
              <PackageStatusComponent packageInfo={packageInfo} />
            </Column>
            <Column alignmentHorizontal="end" alignmentVertical="top">
              <Button onClick={onExpandDetailsClick} type="link">
                <DesktopView>{t(bundle, 'swiship_order_details')}&nbsp;&nbsp;</DesktopView>
                <Icon tokens={chevronTokens} />
              </Button>
            </Column>
          </Row>

          {isDetailsExpanded ? (
            <Box width="100%">
              &nbsp;
              {getTrackingInformationComponent(packageInfo, isMobile, bundle)}
              {trackingHistoryComponent}
            </Box>
          ) : null}
        </Card>
      );
  }
);

export default PackageComponent;
