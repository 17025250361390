import { Region } from './DomainUtils';
import { DOMAIN_INFO } from 'src/utils/WindowUtils';

export const zipCodeFormatters: { [region: string]: (zipCode: string) => string } = {
  [Region.NA]: (zipCode) => {
    // Check if it's a Canadian postal code format (A1A 1A1 or A1A1A1)
    if (/^[A-Za-z]\d[A-Za-z]\s?\d?[A-Za-z]?\d?$/.test(zipCode)) {
      // Remove spaces
      zipCode = zipCode.replace(/\s/g, '');
      // If it's a full postal code, remove the last 3 characters
      if (zipCode.length >= 6) {
        return zipCode.substring(0, 3);
      }
      // If it's already 3 characters or less, return as is
      return zipCode;
    }
    // For US and other NA countries, take the first 5 digits
    return zipCode.substring(0, 5);
  },
  [Region.EU]: (zipCode) => {
    const parts = zipCode.split(' ');
    if (parts.length === 2) {
      return `${parts[0]} ${parts[1][0]}`;
    }
    // If the format is unexpected, return the first 5 characters
    return zipCode.substring(0, 5);
  },
  [Region.FE]: (zipCode) => {
    // Check if the zipcode matches the pattern XXX-XXXX
    if (/^\d{3}-\d{4}$/.test(zipCode)) {
      // If it matches, return the first 7 characters (XXX-XXX)
      return zipCode.substring(0, 7);
    }
    // If it doesn't match the pattern, return it as is which is for AU
    return zipCode;
  },
};

export function trimZipCode(zipCode: string): string {
  const region = DOMAIN_INFO?.region || Region.NA;
  const formatZipCode = zipCodeFormatters[region] || zipCodeFormatters[Region.NA];
  return formatZipCode(zipCode);
}

export function getTrimZipCode(): (zipCode: string) => string {
  return (zipCode: string) => trimZipCode(zipCode);
}
