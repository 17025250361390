import { makeObservable, observable, action, flow, runInAction } from 'mobx';
import * as KatalMetrics from '@amzn/katal-metrics';
import initialMetricsPublisher from 'src/metrics';
import { logger } from 'src/logger';
import { INCORRECT_ZIP_CODE, STATES } from 'src/utils/Constants';
import { ProofOfDeliveryDetails } from 'src/stores/ProofOfDeliveryDetails';
import { getPackageDeliveryDetails } from 'src/utils/api';
import { AxiosResponse } from 'axios';
import { createContext } from 'react';

export class PackageDeliveryDetails {
  @observable protected _loadingState: string = STATES.DONE;
  @observable protected _trackingNumber = '';
  @observable protected _proofOfDeliveryDetails: ProofOfDeliveryDetails =
    new ProofOfDeliveryDetails();
  @observable protected _errorMessage = '';
  protected packageDeliveryDetail: AxiosResponse | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setLoadingState(loadingState: string): void {
    this._loadingState = loadingState;
  }

  get getLoadingState(): string {
    return this._loadingState;
  }

  @action
  setTrackingNumber(trackingNumber: string): void {
    this._trackingNumber = trackingNumber;
  }

  get getTrackingNumber(): string {
    return this._trackingNumber;
  }

  @action
  setProofOfDeliveryDetails(proofOfDeliveryDetails: ProofOfDeliveryDetails): void {
    this._proofOfDeliveryDetails = proofOfDeliveryDetails;
  }

  get getProofOfDeliveryDetails(): ProofOfDeliveryDetails {
    return this._proofOfDeliveryDetails;
  }

  @action
  setErrorMessage(errorMessage: string): void {
    this._errorMessage = errorMessage;
  }

  get getErrorMessage(): string {
    return this._errorMessage;
  }

  fetchPackageDeliveryDetails = flow(function* (
    this: PackageDeliveryDetails,
    trackingId: string,
    deliveryPostalCode: string
  ) {
    if (this._loadingState !== STATES.PENDING) {
      return;
    }

    const fetchPackageDeliveryDetailsMetricsPublisher =
      initialMetricsPublisher.newChildActionPublisherForMethod('FetchPackageDeliveryDetails');
    const fetchPackageDeliveryDetailsHandlerMetric = new KatalMetrics.Metric.TimedAttempt(
      'FetchPackageDeliveryDetails'
    ).withMonitor();
    logger.info(`deliveryPostalCode', ${trackingId} + '-' +  ${deliveryPostalCode}`);
    try {
      runInAction(() => {
        this.setErrorMessage('');
      });

      this.packageDeliveryDetail = yield getPackageDeliveryDetails(trackingId, deliveryPostalCode);

      if (this.packageDeliveryDetail && this.packageDeliveryDetail.data) {
        const responseData = this.packageDeliveryDetail.data;

        runInAction(() => {
          if (responseData.deliveryDetails && responseData.deliveryDetails.proofOfDeliveryDetails) {
            const packageProofOfDeliveryDetails: ProofOfDeliveryDetails =
              new ProofOfDeliveryDetails();
            packageProofOfDeliveryDetails.setProofOfDeliveryDetails(
              responseData.deliveryDetails.proofOfDeliveryDetails
            );
            this.setProofOfDeliveryDetails(packageProofOfDeliveryDetails);
          }

          this.setTrackingNumber(responseData.trackingNumber);
          this._loadingState = STATES.DONE;
        });

        fetchPackageDeliveryDetailsHandlerMetric.setSuccess();
      } else {
        runInAction(() => {
          this._loadingState = STATES.ERROR;
        });
        fetchPackageDeliveryDetailsHandlerMetric.setFailure();
      }
    } catch (error: any) {
      runInAction(() => {
        this._loadingState = STATES.ERROR;
        if (error.response && error.response.status === 400) {
          this.setErrorMessage(INCORRECT_ZIP_CODE);
        } else {
          this.setErrorMessage(STATES.ERROR);
        }
      });

      fetchPackageDeliveryDetailsHandlerMetric.setFailure();
      logger.error(
        `Error fetching package delivery details for tracking id: ${trackingId} with error: ${error}`
      );
    } finally {
      fetchPackageDeliveryDetailsMetricsPublisher.publish(fetchPackageDeliveryDetailsHandlerMetric);
    }
  });
}

export default createContext(new PackageDeliveryDetails());
