import { SHORT_DATE, _12_HOUR_CLOCK_TIME } from 'src/utils/Formatters';
import React from 'react';
import { PackageDeliveryWindow } from 'src/stores/PackageInfo';
import { logger } from 'src/logger';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

interface DeliveryWindowComponentProps {
  deliveryWindow: PackageDeliveryWindow;
}

const DeliveryWindowComponent = ({
  deliveryWindow,
}: DeliveryWindowComponentProps): React.ReactElement => {
  const startDate = new Date(deliveryWindow.startDate);
  const endDate = new Date(deliveryWindow.endDate);
  const diffInHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  logger.info('DeliveryWindowComponent loaded');

  if (diffInDays === 0) {
    // If the start and end dates are the same day
    const formattedStartTime = _12_HOUR_CLOCK_TIME.format(startDate);
    const formattedEndTime = _12_HOUR_CLOCK_TIME.format(endDate);

    if (diffInHours < 4) {
      // If the delivery window is less than 4 hours, display start and end times
      return (
        <div>
          {SHORT_DATE.format(startDate)}, {formattedStartTime} - {formattedEndTime}
        </div>
      );
    } else {
      // If the delivery window is 4 hours or more, display only the end time
      return (
        <div>
          {t(bundle, 'swiship_order_delivery_by_label')} {SHORT_DATE.format(endDate)},{' '}
          {formattedEndTime}
        </div>
      );
    }
  } else if (diffInDays === 1) {
    // If the difference is one day, display only the end date and time
    const formattedEndTime = _12_HOUR_CLOCK_TIME.format(endDate);
    return (
      <div>
        {t(bundle, 'swiship_order_delivery_by_label')} {SHORT_DATE.format(endDate)},{' '}
        {formattedEndTime}
      </div>
    );
  } else {
    // If the difference is more than one day, display only the end date
    return (
      <div>
        {t(bundle, 'swiship_order_delivery_by_label')} {SHORT_DATE.format(endDate)}
      </div>
    );
  }
};

export default DeliveryWindowComponent;
