import * as KatalMetrics from '@amzn/katal-metrics';
import { createAWSMetricsPublisher } from '@amzn/katal-metrics-mons';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  // Note that if we are in production or SERVER_MODE 'mons' we auto-configure before running this function.
  // So for Mons apps these configurations are just for unit tests and local server tests.
  // @ts-expect-error expected for process
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
    // @ts-expect-error expected for process
  } else if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    return new KatalMetricsDriverSushi.Builder()
      .withDomainRealm('test', 'USAmazon')
      .withErrorHandler(metricsConsoleErrorHandler)
      .build();
  }
};

const makePublisher = (): KatalMetrics.Publisher => {
  if (!SERVER_MODE || SERVER_MODE === 'mons') {
    return createAWSMetricsPublisher({
      url: '/track/metrics',
    });
  }
  // Otherwise we are running a test server outside of Mons so cannot use KatalMetricsMons to auto-configure.
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('Swiship')
    .withServiceName('SwishipMonsWebsite')
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext
  );
};

const initialMetricsPublisher = makePublisher();

export const publishSingleMetricCount = (methodName: string): void => {
  const actionMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod(methodName);
  actionMetricsPublisher.publishCounterMonitor('count', 1);
};

export default initialMetricsPublisher;
