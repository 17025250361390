import { TFunction } from 'i18next';
import moment from 'moment';

export const LOCALE_QUERY_PARAM = 'loc';
export const TRACKING_ID_QUERY_PARAM = 'id';
export const DESIGN_ID_QUERY_PARAM = '71D4532B26014F6D9B762C792E174AE4';

export const STATES = {
  PENDING: 'pending',
  DONE: 'done',
  ERROR: 'error',
  UNKNOWN_TRACKING_NUMBER: 'unknown_tracking_number',
  OLD_TRACKING_ID_OR_TRACKING_DATA_NOT_FOUND: 'old_tracking_id_tracking_data_not_found',
  TRACKING_TOO_EARLY: 'tracking_too_early',
};

export const DEFAULT_PAGE_WIDTH = 780;
export const MINIMAL_PAGE_WIDTH = 280;
export const PAGE_CONTENT_BORDERS_WIDTH = 48;
export const PAGE_CONTENT_WIDTH = DEFAULT_PAGE_WIDTH - PAGE_CONTENT_BORDERS_WIDTH;

export const PACKAGES_COUNT_PER_PAGE = 5;
export const TRACKING_HISTORY_MINIMAL_NUMBER_OF_ROWS = 4;
export const INCORRECT_ZIP_CODE = 'incorrect_zip_code';

export const DEFAULT_LOCALE = 'en-US';
export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';

export const POD_IMAGE_ERROR_METRIC = 'ProofOfDeliveryImageError';
export const POD_IMAGE_SUCCESS_METRIC = 'ProofOfDeliveryImageSuccess';
export const POD_IMAGE_VALIDATION_ERROR_METRIC = 'ProofOfDeliveryValidationError';

export const PROGRESS_TRACKER_FIELDS: {
  [key: string]: { [key: string]: any };
} = {
  OUT_FOR_DELIVERY: {
    trackerStep1Type: 'past', // past, present, future
    trackerStep2Type: 'past',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'none',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_out_for_delivery',
    trackerStep3FallBackText: 'Out for Delivery',
  },
  DELIVERY_ATTEMPTED: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'past',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'warning',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_delivery_attempted',
    trackerStep3FallBackText: 'Delivery Attempted',
  },
  UNDELIVERABLE: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'past',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'error',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_undeliverable',
    trackerStep3FallBackText: 'Undeliverable',
  },
  RETURNING: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'past',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'error',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_returning',
    trackerStep3FallBackText: 'Returning',
  },
  DELIVERED: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'past',
    trackerStep3Type: 'past',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'none',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_package_delivered',
    trackerStep3FallBackText: 'Delivered',
  },
  IN_TRANSIT: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'present',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'none',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: '',
    trackerStep3FallBackText: '',
  },
  AVAILABLE_FOR_PICKUP: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'past',
    trackerStep3Type: 'past',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'none',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_available_for_pickup',
    trackerStep3FallBackText: 'Available for pickup',
  },
  CUSTOMER_ACTION_REQUIRED: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'past',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'none',
    trackerAlert3Type: 'warning',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_package_in_transit',
    trackerStep2FallBackText: 'In-Transit',
    trackerStep3TextId: 'swiship_customer_action_required',
    trackerStep3FallBackText: 'Customer action required',
  },
  DELAYED: {
    trackerStep1Type: 'past',
    trackerStep2Type: 'present',
    trackerStep3Type: 'future',
    trackerAlert1Type: 'none',
    trackerAlert2Type: 'warning',
    trackerAlert3Type: 'warning',
    trackerStep1TextId: 'swiship_package_received',
    trackerStep1FallBackText: 'Shipped',
    trackerStep2TextId: 'swiship_delayed',
    trackerStep2FallBackText: 'Delayed',
    trackerStep3TextId: '',
    trackerStep3FallBackText: '',
  },
};

export const PACKAGE_STATUS_FIELDS: { [key: string]: { [key: string]: any } } = {
  DELIVERED: {
    displayText: true,
    textId1: 'swiship_delivered',
    defaultText1: 'Delivered',
    fontType1: 'h200',

    displayDate: true,
    fontType2: 'b200',

    displayTime: true,
    fontType3: 'b200',
  },
  DELAYED: {
    displayText: true,
    textId1: 'swiship_delivery_estimate',
    defaultText1: 'DELIVERY ESTIMATE',
    fontType1: 'b100',

    displayDate: true,
    fontType2: 'h200',
    extraTextId: 'swiship_delayed',
    extraText: 'Delayed',

    displayTime: true,
    fontType3: 'b200',
  },
  UNDELIVERABLE: {
    displayText: true,
    textId1: 'swiship_delivery_estimate',
    defaultText1: 'DELIVERY ESTIMATE',
    fontType1: 'b100',

    displayDate: true,
    fontType2: 'h200',
    extraTextId: 'swiship_undeliverable',
    extraText: 'Undeliverable',

    displayTime: true,
    fontType3: 'b200',
  },
  RETURNING: {
    displayText: true,
    textId1: 'swiship_delivery_estimate',
    defaultText1: 'DELIVERY ESTIMATE',
    fontType1: 'b100',

    displayDate: true,
    fontType2: 'h200',
    extraTextId: 'swiship_returning',
    extraText: 'Returning',

    displayTime: true,
    fontType3: 'b200',
  },
};

export const DEFAULT_PACKAGE_STATUS_FIELDS = {
  displayText: true,
  textId1: 'swiship_delivery_estimate',
  defaultText1: 'DELIVERY ESTIMATE',
  fontType1: 'b100',

  displayDate: true,
  fontType2: 'h200',

  displayTime: true,
  fontType3: 'b200',
};

const TrackingNumberDelimeter = '$$$$$';
const CARRIER_NAMES = [
  {
    carrierName: 'Fedex',
    carrierURL: 'http://printtracking.fedex.com/trackOrder.do?gtns=' + TrackingNumberDelimeter,
  },
  {
    carrierName: 'UPS',
    carrierURL: 'https://www.ups.com/track?loc=en_US&tracknum=' + TrackingNumberDelimeter,
  },
  {
    carrierName: 'USPS',
    carrierURL: 'https://tools.usps.com/go/TrackConfirmAction?tLabels=' + TrackingNumberDelimeter,
  },
  {
    carrierName: 'DHL',
    carrierURL: 'https://www.dhl.com/en/express/tracking.html?AWB=' + TrackingNumberDelimeter,
  },
];

/**
 * Gets the external tracking link for carriers such as Fedex, USPS, UPS etc
 * return null for unknown carriers, in which case the UI displays no link
 * @param shipMethod
 * @param trackingNumber
 */
export const getExternalTrackingLink = (shipMethod: string, trackingNumber: string): any => {
  let externalTrackingLink: string | null = null;
  for (let i = 0; i < CARRIER_NAMES.length; i++) {
    const carrier = CARRIER_NAMES[i];
    if (shipMethod.startsWith(carrier.carrierName)) {
      externalTrackingLink = carrier.carrierURL.replace(TrackingNumberDelimeter, trackingNumber);
      break;
    }
  }
  return externalTrackingLink;
};

/**
 * Helper function used for translation, with singular/plural form and argument/string replacement
 * @param translateFunction translate function
 * @param keys translation keys for singular or plural form
 * @param args arguments to replace with
 * @param count number that will be used to choose singular/plural form
 */
export function getTranslation(
  translateFunction: TFunction,
  keys: string[],
  args?: [string, string | number | undefined][],
  count?: number
): string | undefined {
  const index = count ? (count === 1 ? 0 : 1) : 0;
  let translation = translateFunction(keys[index]);

  args?.forEach((keyValue) => {
    translation = translation.replace(keyValue[0], '' + keyValue[1]);
  });

  return translation;
}

export enum DayFormat {
  short = 'D',
  long = 'Do',
}

export function getLocalizedMonthDayString(date: Date, dayFormat = DayFormat.short): string {
  const locale = moment.locale();

  let formattedDate: string;
  switch (locale) {
    case 'it':
    case 'fr':
    case 'de':
    case 'es':
      formattedDate = moment(date).format(dayFormat + ' MMMM');
      break;
    default:
      formattedDate = moment(date).format('MMMM ' + dayFormat);
      break;
  }

  return formattedDate;
}

/**
 * Return localized hour string i.e. 16pm, 8am, 16:00, 8:00
 * @param date
 */
export function getLocalizedHourString(date: moment.Moment): string {
  const locale = moment.locale();

  let formattedTime = date.format('LT');
  switch (locale) {
    case 'en':
      // There is no localized 'hour' format method in moment, so replace is needed here
      formattedTime = formattedTime.replace(/([:.])[0-9]{2}/, '');
      break;
  }

  return formattedTime.toLowerCase();
}
