import React from 'react';
import { observer } from 'mobx-react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import { italicize } from 'src/utils/Formatters';
import moment from 'moment-timezone';
import './TrackingEventComponent.scss';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

/**
 * Given a TrackingEvent data, returns a React Component to display
 * @param trackingEvent
 */
export const TrackingEventComponent = observer(({ trackingEvent }: any) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  return (
    <Row widths={['110px', '30rem']} alignmentVertical="top" className="tracking-event-row">
      <Column height="fill" alignmentVertical="top">
        <Text type="b300">{moment(trackingEvent.getEventDate).format('LT')}</Text>
      </Column>
      <Column
        heights={['fit', 'fit']}
        alignmentVertical="top"
        alignmentHorizontal="start"
        spacing="xxsmall"
        className="track-event-border"
      >
        <Text type="b300">{t(bundle, trackingEvent.getEventDescription)}</Text>
        {italicize(
          <Text type="b300">{trackingEvent.getEventAddress.replace(/\s+/g, ' ').trim()}</Text>
        )}
      </Column>
    </Row>
  );
});

export default TrackingEventComponent;
