var endpointMap = {
  ar: {
    'pages.homepage': 'translation-ar.json',
  },
  'ar-AE': {
    'pages.homepage': 'translation-ar.json',
  },
  bn: {
    'pages.homepage': 'translation-bn.json',
  },
  'bn-IN': {
    'pages.homepage': 'translation-bn.json',
  },
  de: {
    'pages.homepage': 'translation-de.json',
  },
  'de-DE': {
    'pages.homepage': 'translation-de.json',
  },
  en: {
    'pages.homepage': 'translation-en.json',
  },
  'en-AU': {
    'pages.homepage': 'translation-en.json',
  },
  'en-CA': {
    'pages.homepage': 'translation-en.json',
  },
  'en-GB': {
    'pages.homepage': 'translation-en.json',
  },
  'en-IE': {
    'pages.homepage': 'translation-en.json',
  },
  'en-IN': {
    'pages.homepage': 'translation-en.json',
  },
  'en-SG': {
    'pages.homepage': 'translation-en.json',
  },
  'en-US': {
    'pages.homepage': 'translation-en.json',
  },
  'en-ZA': {
    'pages.homepage': 'translation-en.json',
  },
  es: {
    'pages.homepage': 'translation-es.json',
  },
  'es-ES': {
    'pages.homepage': 'translation-es.json',
  },
  'es-MX': {
    'pages.homepage': 'translation-es.json',
  },
  fr: {
    'pages.homepage': 'translation-fr.json',
  },
  'fr-BE': {
    'pages.homepage': 'translation-fr.json',
  },
  'fr-CA': {
    'pages.homepage': 'translation-fr.json',
  },
  'fr-FR': {
    'pages.homepage': 'translation-fr.json',
  },
  gu: {
    'pages.homepage': 'translation-gu.json',
  },
  'gu-IN': {
    'pages.homepage': 'translation-gu.json',
  },
  hi: {
    'pages.homepage': 'translation-hi.json',
  },
  'hi-IN': {
    'pages.homepage': 'translation-hi.json',
  },
  it: {
    'pages.homepage': 'translation-it.json',
  },
  'it-IT': {
    'pages.homepage': 'translation-it.json',
  },
  ja: {
    'pages.homepage': 'translation-ja.json',
  },
  'ja-JP': {
    'pages.homepage': 'translation-ja.json',
  },
  kn: {
    'pages.homepage': 'translation-kn.json',
  },
  'kn-IN': {
    'pages.homepage': 'translation-kn.json',
  },
  ko: {
    'pages.homepage': 'translation-ko.json',
  },
  'ko-KR': {
    'pages.homepage': 'translation-ko.json',
  },
  ml: {
    'pages.homepage': 'translation-ml.json',
  },
  'ml-IN': {
    'pages.homepage': 'translation-ml.json',
  },
  mr: {
    'pages.homepage': 'translation-mr.json',
  },
  'mr-IN': {
    'pages.homepage': 'translation-mr.json',
  },
  nl: {
    'pages.homepage': 'translation-nl.json',
  },
  'nl-BE': {
    'pages.homepage': 'translation-nl.json',
  },
  'nl-NL': {
    'pages.homepage': 'translation-nl.json',
  },
  pl: {
    'pages.homepage': 'translation-pl.json',
  },
  'pl-PL': {
    'pages.homepage': 'translation-pl.json',
  },
  pt: {
    'pages.homepage': 'translation-pt.json',
  },
  'pt-BR': {
    'pages.homepage': 'translation-pt.json',
  },
  'pt-PT': {
    'pages.homepage': 'translation-pt.json',
  },
  sv: {
    'pages.homepage': 'translation-sv.json',
  },
  'sv-SE': {
    'pages.homepage': 'translation-sv.json',
  },
  ta: {
    'pages.homepage': 'translation-ta.json',
  },
  'ta-IN': {
    'pages.homepage': 'translation-ta.json',
  },
  te: {
    'pages.homepage': 'translation-te.json',
  },
  'te-IN': {
    'pages.homepage': 'translation-te.json',
  },
  th: {
    'pages.homepage': 'translation-th.json',
  },
  'th-TH': {
    'pages.homepage': 'translation-th.json',
  },
  tr: {
    'pages.homepage': 'translation-tr.json',
  },
  'tr-TR': {
    'pages.homepage': 'translation-tr.json',
  },
  und: {
    'pages.homepage': 'translation.json',
  },
  vi: {
    'pages.homepage': 'translation-vi.json',
  },
  'vi-VN': {
    'pages.homepage': 'translation-vi.json',
  },
  zh: {
    'pages.homepage': 'translation-zh.json',
  },
  'zh-CN': {
    'pages.homepage': 'translation-zh.json',
  },
  'zh-TW': {
    'pages.homepage': 'translation-zh.json',
  },
};
var arbManifest = {
  endpointMap: endpointMap,
};

export { arbManifest as default, endpointMap };
