import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import Expander from '@amzn/meridian/expander';
import Text from '@amzn/meridian/text';
import OrderInfoContext from '../../stores/OrderInfo';
import Tracker from './Tracker';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import TrackingHistory from 'src/components/TrackPage/TrackingHistory';
import { getExternalTrackingLink, STATES } from 'src/utils/Constants';
import CustomLoader from 'src/components/TrackPage/CustomLoader';
import UnknownTrackingNumber, {
  OldTrackingNumber,
} from 'src/components/ErrorPage/UnknownTrackingNumber';
import BackendFailure from 'src/components/ErrorPage/BackendFailure';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import iconLink from '@amzn/meridian-tokens/base/icon/export-small';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { MessageBundle } from '@amzn/arb-tools';

const getExternalTrackingLinkComponent = (carrierName: string, trackingNumber: string): any => {
  const externalTrackingLink = getExternalTrackingLink(carrierName, trackingNumber);

  if (externalTrackingLink) {
    return (
      <Text type="h100">
        {carrierName}{' '}
        <Link
          href={externalTrackingLink}
          type="primary"
          disabled={false}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trackingNumber} <Icon tokens={iconLink} />
        </Link>
      </Text>
    );
  } else {
    return (
      <Text type="h100">
        {carrierName} {trackingNumber}
      </Text>
    );
  }
};

const getTrackingTooSoonComponent = (bundle: MessageBundle): any => {
  return (
    <Text id="tracking_too_soon" type="b300">
      {t(bundle, 'swiship_tracking_too_soon_message')}
    </Text>
  );
};

export const TrackingContent = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const orderInfo = useContext(OrderInfoContext);

  const [isTrackingHistoryExpanded, setTrackingHistoryExpanded] = useState(true);
  const packageInfo = orderInfo.getCurrentPackage;
  if (!packageInfo) {
    return null;
  }

  const trackingHistoryComponent = (
    <TrackingHistory trackingEvents={packageInfo.getTrackingDetail.getGroupedTrackingEventsByDay} />
  );
  const trackingTooSoonComponent = getTrackingTooSoonComponent(bundle);
  let showTrackingTooEarlyMessage = false;
  if (packageInfo.getLoadingState === STATES.PENDING) {
    return <CustomLoader />;
  } else if (packageInfo.getLoadingState === STATES.ERROR) {
    return <BackendFailure />;
  } else if (packageInfo.getLoadingState === STATES.UNKNOWN_TRACKING_NUMBER) {
    return <UnknownTrackingNumber />;
  } else if (packageInfo.getLoadingState === STATES.OLD_TRACKING_ID_OR_TRACKING_DATA_NOT_FOUND) {
    return <OldTrackingNumber />;
  } else if (packageInfo.getLoadingState === STATES.TRACKING_TOO_EARLY) {
    showTrackingTooEarlyMessage = true;
  }

  return (
    <Column heights={'fit'} alignmentVertical="top" alignmentHorizontal="start" spacing="large">
      {getExternalTrackingLinkComponent(
        packageInfo.getDisplayableCarrierName,
        packageInfo.getTrackingNumber
      )}
      <Row minWidth="100%" alignmentVertical="center" alignmentHorizontal="center">
        <div id={'progressTracker'}>
          <Tracker transitState={packageInfo.getTransitState} />
        </div>
      </Row>
      <div id={'trackingHistoryExpander'}>
        <Expander
          type="inline"
          open={isTrackingHistoryExpanded}
          title={t(bundle, 'swiship_tracking_history')}
          onChange={() => setTrackingHistoryExpanded(!isTrackingHistoryExpanded)}
        >
          {showTrackingTooEarlyMessage ? trackingTooSoonComponent : trackingHistoryComponent}
        </Expander>
      </div>
    </Column>
  );
});

export default TrackingContent;
