import React, { useContext } from 'react';
import SearchField from '@amzn/meridian/search-field';
import { observer } from 'mobx-react';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import SearchPageContext, { SearchPageStore } from '../../stores/SearchPageStore';
import { LOCALE_QUERY_PARAM } from 'src/utils/Constants';
import { changeURL, useQuery } from 'src/utils/URLUtils';
import { publishSingleMetricCount } from 'src/metrics';
import { trim } from 'src/utils/Formatters';
import { scrollToTop } from 'src/utils/WindowUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

const setSearchValue = (searchValue: string, searchPageStore: SearchPageStore): void => {
  searchPageStore.setSearchValue(searchValue);
};

const handleSubmit = (
  searchPageStore: SearchPageStore,
  navigate: NavigateFunction,
  locale: string
): void => {
  const trackingId = trim(searchPageStore.getSearchValue);
  searchPageStore.setSearchValue(trackingId);
  if (trackingId) {
    changeURL(navigate, locale, trackingId);
    publishSingleMetricCount('SearchButtonClick');
    scrollToTop();
  }
};

export const SearchBox = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const navigate = useNavigate();
  const query = useQuery();
  const locale = query.get(LOCALE_QUERY_PARAM) || '';
  const searchPageStore = useContext(SearchPageContext);

  return (
    <div id="searchBox">
      <SearchField
        value={searchPageStore.getSearchValue}
        placeholder={t(bundle, 'swiship_enter_tracking_id')}
        onChange={(value) => setSearchValue(value, searchPageStore)}
        onSubmit={() => {
          handleSubmit(searchPageStore, navigate, locale);
        }}
        size="small"
        searchButton={true}
      />
    </div>
  );
});

export default SearchBox;
