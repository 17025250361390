import React, { useContext, useState } from 'react';
import Modal from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Link from '@amzn/meridian/link/link';
import { ProofOfDeliveryDetails } from 'src/stores/ProofOfDeliveryDetails';
import PackageDeliveryDetailsContext from 'src/stores/PackageDeliveryDetails';
import {
  POD_IMAGE_ERROR_METRIC,
  POD_IMAGE_SUCCESS_METRIC,
  POD_IMAGE_VALIDATION_ERROR_METRIC,
  STATES,
} from 'src/utils/Constants';
import Text from '@amzn/meridian/text';
import Loader from '@amzn/meridian/loader';
import Column from '@amzn/meridian/column';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { logger } from 'src/logger';
import { getTrimZipCode } from 'src/utils/ZipCodeUtils';
import { publishSingleMetricCount } from 'src/metrics';

interface ZipCodeModalProps {
  trackingNumber: string;
}

const ZipCodeModal: React.FC<ZipCodeModalProps> = ({ trackingNumber }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState<string>('');
  const [proofOfDeliveryDetails, setProofOfDeliveryDetails] =
    useState<ProofOfDeliveryDetails | null>(null);
  const [error, setError] = useState<string | undefined>('');
  const [zipCodeError, setZipCodeError] = useState<string | undefined>('');
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const packageDeliveryDetails = useContext(PackageDeliveryDetailsContext);
  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  const genericZipCodeRegex = /^[A-Z0-9\s-]{2,10}([A-Z]{2})?$/i;
  const trimZipCode = getTrimZipCode();

  const handleZipCodeChange = (value: string): void => {
    setError('');
    setZipCode(value);

    if (value.length >= 3) {
      if (genericZipCodeRegex.test(value)) {
        setZipCodeError('');
      } else {
        setZipCodeError(t(bundle, 'swiship_invalid_zip_code_format'));
        publishSingleMetricCount(POD_IMAGE_VALIDATION_ERROR_METRIC);
      }
    } else {
      setZipCodeError('');
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (zipCodeError) {
      return; // Don't submit if there's a zip code error
    }
    setError('');
    try {
      if (trackingNumber) {
        publishSingleMetricCount('ZipCodeModalSubmit');
        packageDeliveryDetails.setLoadingState(STATES.PENDING);
        const trimmedZipCode = trimZipCode(zipCode);
        await packageDeliveryDetails.fetchPackageDeliveryDetails(trackingNumber, trimmedZipCode);
        if (packageDeliveryDetails.getErrorMessage) {
          setError(packageDeliveryDetails.getErrorMessage);
          publishSingleMetricCount(POD_IMAGE_ERROR_METRIC);
          setProofOfDeliveryDetails(null);
        } else if (packageDeliveryDetails?.getProofOfDeliveryDetails) {
          if (
            Array.isArray(packageDeliveryDetails.getProofOfDeliveryDetails) &&
            packageDeliveryDetails.getProofOfDeliveryDetails.length === 0
          ) {
            setError(t(bundle, 'swiship_something_went_wrong'));
            setProofOfDeliveryDetails(null);
          } else {
            setProofOfDeliveryDetails(packageDeliveryDetails.getProofOfDeliveryDetails);
            publishSingleMetricCount(POD_IMAGE_SUCCESS_METRIC);
            setError('');
          }
        } else {
          setError(t(bundle, 'swiship_no_delivery_details'));
          setProofOfDeliveryDetails(null);
        }
      } else {
        setError(t(bundle, 'swiship_invalid_tracking_id'));
      }
    } catch (err: any) {
      setError(STATES.ERROR);
      setProofOfDeliveryDetails(null);
      publishSingleMetricCount(POD_IMAGE_ERROR_METRIC);
      await logger.error('Error during submission:', err);
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      await handleSubmit();
    }
  };

  return (
    <>
      <Link onClick={handleOpen} type="primary" data-id="view-delivery-photo-link">
        {t(bundle, 'swiship_view_delivery_photo')}
      </Link>
      <Modal
        title={t(bundle, 'swiship_delivery_photo')}
        open={open}
        onClose={handleClose}
        scrollContainer="viewport"
        closeLabel={t(bundle, 'swiship_close_button')}
        aria-describedby="modal-description"
        width={500}
        data-id="delivery-photo-modal"
      >
        {!error &&
        proofOfDeliveryDetails &&
        proofOfDeliveryDetails?.getProofOfDeliveryDetails.length > 0 ? (
          proofOfDeliveryDetails.getProofOfDeliveryDetails.map((proof, index) => (
            <div key={index}>
              {proof.imageType ? (
                <>
                  <img
                    width="100%"
                    height="100%"
                    src={proof.imageUrl}
                    alt={t(bundle, 'swiship_delivery_proof')}
                    className="photo-on-delivery-image"
                  />
                </>
              ) : (
                <Text>{t(bundle, 'swiship_something_went_wrong')}</Text>
              )}
            </div>
          ))
        ) : (
          <>
            {packageDeliveryDetails.getLoadingState === STATES.PENDING ? (
              <Column alignmentHorizontal="center">
                <Loader data-testid="loader"></Loader>
              </Column>
            ) : (
              <>
                <Text>{t(bundle, 'swiship_enter_zip_code_label')}</Text>
                <Column spacingInset="200 none">
                  <Input
                    id="zip-code"
                    data-testid="zip-code"
                    type="text"
                    placeholder={t(bundle, 'swiship_zip_code_placeholder')}
                    value={zipCode}
                    onChange={handleZipCodeChange}
                    onKeyDown={handleKeyDown}
                  />
                </Column>
                {zipCodeError && (
                  <Text color="error" className="zipcode-error">
                    {zipCodeError}
                  </Text>
                )}
                {error && (
                  <Text color="error">
                    {error === STATES.ERROR
                      ? t(bundle, 'swiship_something_went_wrong')
                      : t(bundle, 'swiship_incorrect_zip_code')}
                  </Text>
                )}
                <br />
                <Button onClick={handleSubmit} id="delivery-photo-submit">
                  {t(bundle, 'swiship_submit_button')}
                </Button>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ZipCodeModal;
