export const RECYCLE_PAGE_ASSETS: {
  image: string;
  title: string;
  description: string;
}[] = [
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/CardboardBox.png',
    title: 'swiship_recycle_cardboard_box_title',
    description: 'swiship_recycle_cardboard_box_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PaperEnvelope.png',
    title: 'swiship_recycle_paper_envelope_title',
    description: 'swiship_recycle_paper_envelope_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/CardboardEnvelope.png',
    title: 'swiship_recycle_cardboard_envelope_title',
    description: 'swiship_recycle_cardboard_envelope_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PaperBag.png',
    title: 'swiship_recycle_paper_bag_title',
    description: 'swiship_recycle_paper_bag_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PackagingPaper.png',
    title: 'swiship_recycle_packing_paper_title',
    description: 'swiship_recycle_packing_paper_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PaddedMailer.png',
    title: 'swiship_recycle_paper_padded_mailer_title',
    description: 'swiship_recycle_paper_padded_mailer_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/GiftBags.png',
    title: 'swiship_recycle_gift_bags_title',
    description: 'swiship_recycle_gift_bags_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PlasticFilms.png',
    title: 'swiship_recycle_packing_film_bags_title',
    description: 'swiship_recycle_packing_film_bags_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PackagingLabels.png',
    title: 'swiship_recycle_packing_label_title',
    description: 'swiship_recycle_packing_label_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PackingTape.png',
    title: 'swiship_recycle_packing_tape_title',
    description: 'swiship_recycle_packing_tape_description',
  },
  {
    image: 'https://m.media-amazon.com/images/G/01/Swiship/RecyclePage/PlasticTape.png',
    title: 'swiship_recycle_plastic_tape_title',
    description: 'swiship_recycle_plastic_tape_description',
  },
];
