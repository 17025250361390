import { useEffect, useState } from 'react';
import {
  MINIMAL_PAGE_WIDTH,
  PAGE_CONTENT_BORDERS_WIDTH,
  PAGE_CONTENT_WIDTH,
} from 'src/utils/Constants';

interface WindowDimensions {
  windowWidth: number;
  windowHeight: number;
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  return {
    windowWidth,
    windowHeight,
  };
}

function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useContentWidth(): number {
  const { windowWidth } = useWindowDimensions();
  const contentWidth =
    windowWidth - PAGE_CONTENT_BORDERS_WIDTH < PAGE_CONTENT_WIDTH
      ? windowWidth - PAGE_CONTENT_BORDERS_WIDTH
      : PAGE_CONTENT_WIDTH;

  return contentWidth < MINIMAL_PAGE_WIDTH ? MINIMAL_PAGE_WIDTH : contentWidth;
}

export function useIsMobileView(): boolean {
  const { windowWidth } = useWindowDimensions();

  return windowWidth < PAGE_CONTENT_WIDTH;
}
