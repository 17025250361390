import React, { useContext, useEffect } from 'react';
import './TrackPage.scss';
import { observer } from 'mobx-react';
import logo from 'src/images/swiship.png';
import NHSFbaLogo from 'src/images/NHSFulfillmentByAmazonLogo.png';
import SideMenu, { SideMenuLink, SideMenuTitle } from '@amzn/meridian/side-menu';
import TrackingContent from './TrackingContent';
import OrderInfoContext, { OrderInfo } from '../../stores/OrderInfo';
import Text from '@amzn/meridian/text';
import LanguagePicker from '../LanguagePicker';
import SearchBox from 'src/components/SearchPage/SearchBox';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import SearchPageContext, { SearchPageStore } from 'src/stores/SearchPageStore';
import { LOCALE_QUERY_PARAM, STATES, TRACKING_ID_QUERY_PARAM } from 'src/utils/Constants';
import CustomLoader from 'src/components/TrackPage/CustomLoader';
import Divider from '@amzn/meridian/divider';
import Box from '@amzn/meridian/box';
import { useQuery } from 'src/utils/URLUtils';
import BackendFailure from 'src/components/ErrorPage/BackendFailure';
import { publishSingleMetricCount } from 'src/metrics';
import { useNavigate } from 'react-router-dom';
import UnknownTrackingNumber from 'src/components/ErrorPage/UnknownTrackingNumber';
import PackageStatusComponent from 'src/components/TrackPage/PackageStatusComponent';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';

/* eslint-disable react-hooks/rules-of-hooks */
const getMainContent = (orderInfoState: string): any => {
  if (orderInfoState === STATES.PENDING) {
    return <CustomLoader />;
  } else if (orderInfoState === STATES.DONE) {
    return <TrackingContent />;
  } else if (orderInfoState === STATES.ERROR) {
    return <BackendFailure />;
  } else if (orderInfoState === STATES.UNKNOWN_TRACKING_NUMBER) {
    return <UnknownTrackingNumber />;
  } else {
    return null;
  }
};

const getLogo = (orderInfo: OrderInfo): any => {
  // not showing any logo until the getAllPackageInfo backend call is done to avoid image flipping
  if (orderInfo.getLogoImageSource === '') {
    return <></>;
  }

  let image = <img src={logo} height="auto" width="140px" alt="Swiship" />;

  const isNHSImage = orderInfo.getLogoImageSource === 'NHS_FBA_LOGO';
  if (isNHSImage) {
    image = <img src={NHSFbaLogo} height="auto" width="140px" alt="NHS Fulfillment By Amazon" />;
  }

  return image;
};

const handleSideMenuButtonClick = (orderInfo: OrderInfo, packageIndex: number): void => {
  orderInfo.setPackageIndex(packageIndex);
  orderInfo.getPackageInfos[packageIndex].fetchPackageTrackingDetails();
  publishSingleMetricCount('SideButtonClick');
};

const handleImageClick = (
  navigate: any,
  locale: string,
  searchPageStore: SearchPageStore
): void => {
  navigate(`/search?${LOCALE_QUERY_PARAM}=${locale}`);
  searchPageStore.setSearchValue('');
  publishSingleMetricCount('SwishipLogoButtonClick');
};

const getSideMenuComponent = (orderInfo: OrderInfo): any => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const query = useQuery();
  const history = useNavigate();
  const locale = query.get(LOCALE_QUERY_PARAM) || '';
  const searchPageStore = useContext(SearchPageContext);

  return (
    <SideMenu open={true}>
      <SideMenuTitle onClick={() => handleImageClick(history, locale, searchPageStore)}>
        <Row alignmentHorizontal={'center'} alignmentVertical={'center'}>
          <Box width="4px" />
          {getLogo(orderInfo)}
        </Row>
      </SideMenuTitle>
      <SideMenuLink disabled={true}>
        <Column alignmentHorizontal={'center'} alignmentVertical={'center'}>
          <Divider size="small" />
          <Text type="h100">{t(bundle, 'swiship_packages_in_this_order')}</Text>
          <Divider size="small" />
        </Column>
      </SideMenuLink>
      {orderInfo.getPackageInfos.map((packageInfo, index) => {
        return (
          <SideMenuLink
            key={index}
            selected={index === orderInfo.getPackageIndex}
            onClick={() => handleSideMenuButtonClick(orderInfo, index)}
          >
            <div id={'sideMenuButton-' + index}>
              <PackageStatusComponent packageInfo={packageInfo} />
            </div>
          </SideMenuLink>
        );
      })}
    </SideMenu>
  );
};

export const TrackPage = observer(() => {
  const orderInfo = useContext(OrderInfoContext);
  const searchPageStore = useContext(SearchPageContext);
  const query = useQuery();
  const trackingId = query.get(TRACKING_ID_QUERY_PARAM);
  useEffect(() => {
    if (trackingId) {
      searchPageStore.setSearchValue(trackingId);
      orderInfo.fetchAllPackageInfos(trackingId);
    }
    // eslint-disable-next-line
  }, [trackingId]);

  return (
    <Row
      widths={['fit', '40rem']}
      spacing="300"
      alignmentVertical="top"
      alignmentHorizontal="start"
    >
      {getSideMenuComponent(orderInfo)}

      <Column>
        <Row widths={['80%', '20%']}>
          <SearchBox />
          <Column alignmentVertical="center" alignmentHorizontal="end">
            <LanguagePicker />
          </Column>
        </Row>
        {getMainContent(orderInfo.getLoadingState)}
      </Column>
    </Row>
  );
});

export default TrackPage;
