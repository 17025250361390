import React from 'react';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';

export const BackendFailure = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  return (
    <div id={'backendFailureErrorMessage'}>
      <Column alignmentVertical="center" alignmentHorizontal="center" spacing={'300'}>
        <Text color={'error'}>{t(bundle, 'swiship_backend_failure_error_message')}</Text>
      </Column>
    </div>
  );
});

export default BackendFailure;
