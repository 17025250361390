import * as React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SearchPackagesPage from 'src/components/SearchPackagesPage/SearchPackagesPage';
import TrackPackagesPage from 'src/components/TrackPackagesPage/TrackPackagesPage';
import SearchPage from 'src/components/SearchPage/SearchPage';
import TrackPage from 'src/components/TrackPage/TrackPage';
import { observer } from 'mobx-react';
import { useQuery } from 'src/utils/URLUtils';
import {
  DESIGN_ID_QUERY_PARAM,
  HOMEPAGE_MESSAGE_BUNDLE_NAME,
  TRACKING_ID_QUERY_PARAM,
} from 'src/utils/Constants';
import { publishSingleMetricCount } from 'src/metrics';
import Box from '@amzn/meridian/box';
import RecyclePage from 'src/components/RecyclePage/RecyclePage';
import { Routes_Path } from 'src/router/Routes';
import { useEffect, useMemo } from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';

export const ComponentToDisplay = observer(() => {
  const query = useQuery();
  const trackingId = query.get(TRACKING_ID_QUERY_PARAM);
  const newDesign = !query.has(DESIGN_ID_QUERY_PARAM);

  return useMemo(() => {
    if (trackingId) {
      publishSingleMetricCount('RouteToTrackPage');
      return newDesign ? (
        <TrackPackagesPage />
      ) : (
        <Box spacingInset="300">
          <TrackPage />
        </Box>
      );
    } else {
      publishSingleMetricCount('RouteToSearchPage');
      return newDesign ? (
        <SearchPackagesPage />
      ) : (
        <Box spacingInset="300">
          <SearchPage />
        </Box>
      );
    }
  }, [trackingId, newDesign]);
});

const TitleUpdater = observer(() => {
  const location = useLocation();
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

  useEffect(() => {
    if (bundle) {
      let titleKey: string;
      let defaultTitle = 'Track your package';

      switch (location.pathname) {
        case Routes_Path.LANDING_PAGE:
          titleKey = 'swiship_app_page_title_text';
          break;
        case Routes_Path.RECYCLE_PAGE:
          titleKey = 'swiship_recycle_page_title';
          defaultTitle = 'Recycle Your Packaging';
          break;
        default:
          titleKey = 'swiship_app_page_title_text';
      }

      const translatedTitle = t(bundle, titleKey);
      document.title = translatedTitle || defaultTitle;
    }
  }, [location, bundle]);

  return null;
});

export const DiscoveryRouter: React.FC = () => {
  return (
    <>
      <TitleUpdater />
      <Routes>
        <Route path={Routes_Path.RECYCLE_PAGE} element={<RecyclePage />} />
        <Route path={Routes_Path.LANDING_PAGE} element={<ComponentToDisplay />} />
        <Route path="*" element={<Navigate to={Routes_Path.LANDING_PAGE} replace />} />
      </Routes>
    </>
  );
};
