import React from 'react';
import { observer } from 'mobx-react';
import Loader from '@amzn/meridian/loader';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

export const CustomLoader = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  return (
    <Column
      alignmentVertical={'center'}
      alignmentHorizontal={'center'}
      spacing={'300'}
      className="custom-loader"
    >
      <Loader size={'large'} type={'circular'} />
      <Text> {t(bundle, 'swiship_loading')} </Text>
    </Column>
  );
});

export default CustomLoader;
