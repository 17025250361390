import React, { Suspense } from 'react';
import SearchBox from './SearchBox';
import logo from 'src/images/swiship.png';
import LanguagePicker from '../LanguagePicker';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';

export class SearchPage extends React.Component {
  render(): React.ReactElement {
    return (
      <Column>
        <Row alignmentHorizontal="end" alignmentVertical="top">
          <LanguagePicker />
        </Row>
        <Row alignmentHorizontal="center" alignmentVertical="center">
          <Column>
            <img id={'SwishipLogo'} src={logo} alt="logo" height="auto" width="100%" />
            <Suspense fallback={<Loader type="circular" />}>
              <SearchBox />
            </Suspense>
          </Column>
        </Row>
      </Column>
    );
  }
}

export default SearchPage;
