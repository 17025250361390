// localizationManager.ts
import { LocalizationContextBuilder, LocalizationContext } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from 'src/utils/Constants';

const LOCALIZATION_CONTEXTS = new Map<string, LocalizationContext>();

export function getLocalizationContext(locale: string): LocalizationContext {
  let lc = LOCALIZATION_CONTEXTS.get(locale);

  if (!lc) {
    lc = new LocalizationContextBuilder()
      .withLocale(locale)
      .withDefaultLocale(DEFAULT_LOCALE)
      .build();

    LOCALIZATION_CONTEXTS.set(locale, lc);
  }

  return lc;
}

export const localizationContextBuilder = new LocalizationContextBuilder().withDefaultLocale(
  DEFAULT_LOCALE
);
