import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { DEFAULT_PACKAGE_STATUS_FIELDS, PACKAGE_STATUS_FIELDS } from 'src/utils/Constants';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

export const PackageStatusComponent = observer(({ packageInfo }: any) => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  if (!packageInfo) {
    return null;
  }
  const allEvents = packageInfo.getTrackingDetail.getAllEvents;
  let timeToDisplay;
  if (packageInfo.getTransitState === 'DELIVERED' && allEvents[0]) {
    timeToDisplay = allEvents[0].getEventDate;
  } else {
    timeToDisplay = packageInfo.getEstimatedArrivalDateInDateFormat;
  }
  const fields =
    PACKAGE_STATUS_FIELDS[packageInfo.getTransitState] || DEFAULT_PACKAGE_STATUS_FIELDS;
  const extraText = fields.extraText ? '(' + t(bundle, fields.extraTextId) + ')' : '';

  // if the date is still undefined means estimated delivery date from backend is null
  // so not displaying the date in this case
  if (timeToDisplay === undefined) {
    fields.displayDate = false;
    fields.displayTime = false;
  }

  return (
    <Column spacingInset="100" spacing="100" alignmentHorizontal="start" alignmentVertical="center">
      {fields.displayText ? <Text type={fields.fontType1}>{t(bundle, fields.textId1)}</Text> : null}
      {fields.displayDate ? (
        <Text type={fields.fontType2}>{moment(timeToDisplay).format('MMMM Do')}</Text>
      ) : null}
      {fields.extraText ? <Text type={fields.fontType2}>{extraText}</Text> : null}
      {fields.displayTime ? (
        <Text type={fields.fontType3}> {moment(timeToDisplay).format('LT')}</Text>
      ) : null}
    </Column>
  );
});

export default PackageStatusComponent;
