import { makeObservable, observable, action, computed } from 'mobx';
import { LONG_DATE, _12_HOUR_CLOCK_TIME } from 'src/utils/Formatters';

export class TrackingEvent {
  @observable protected _eventDate: Date | undefined;
  @observable protected _eventAddress = '';
  @observable protected _eventDescription = '';

  constructor() {
    makeObservable(this);
  }

  @action
  setEventDate(eventDate: Date | undefined): void {
    this._eventDate = eventDate;
  }

  get getEventDate(): Date | undefined {
    return this._eventDate;
  }

  @computed
  get getEventTime(): string {
    return this._eventDate ? _12_HOUR_CLOCK_TIME.format(this._eventDate) : '';
  }

  @computed
  get getDisplayableDate(): string {
    return this._eventDate ? LONG_DATE.format(this._eventDate) : '';
  }

  @action
  setEventAddress(eventAddress: string): void {
    this._eventAddress = eventAddress;
  }

  get getEventAddress(): string {
    return this._eventAddress;
  }

  @action
  setEventDescription(eventDescription: string): void {
    this._eventDescription = eventDescription;
  }

  get getEventDescription(): string {
    return this._eventDescription;
  }
}
