import React, { useEffect } from 'react';

const CHAT_ELEMENT_ASSET_SRC =
  'https://chat.tuxedo.prod.barossa.dubai.aws.dev/elements/amzn-customer-support/v1.0/web-component.js';

export const ContactUsWidget: React.FC = (): any => {
  useEffect(() => {
    // Create and append the chat widget script to the document head
    const scriptElement = document.createElement('script');
    scriptElement.src = CHAT_ELEMENT_ASSET_SRC;
    scriptElement.type = 'module';
    scriptElement.id = 'assist-element-script';

    // Wait for the script to load before appending the custom element
    scriptElement.onload = (): void => {
      const chatElement = document.createElement('amzn-customer-support');
      chatElement.setAttribute('id', 'amzn-customer-support');
      chatElement.setAttribute('data-testid', 'contact-us-widget');
      chatElement.setAttribute('targetId', 'target-id-for-mcf');
      document.body.appendChild(chatElement);
    };

    document.head.appendChild(scriptElement);

    // Cleanup: Remove elements when component unmounts
    return (): void => {
      if (document.head.contains(scriptElement)) {
        document.head.removeChild(scriptElement);
      }
      const chatElement = document.body.querySelector('amzn-customer-support');
      if (chatElement) {
        document.body.removeChild(chatElement);
      }
    };
  }, []);

  return <div />; // No additional JSX needed as elements are managed in useEffect
};

export default ContactUsWidget;
