import React, { useContext } from 'react';
import logo from 'src/images/swiship.png';
import LanguagePicker from './LanguagePicker';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import { publishSingleMetricCount } from 'src/metrics';
import SearchPageContext, { SearchPageStore } from 'src/stores/SearchPageStore';
import { changeURL, useQuery } from 'src/utils/URLUtils';
import { useNavigate } from 'react-router-dom';
import { DESIGN_ID_QUERY_PARAM, LOCALE_QUERY_PARAM } from 'src/utils/Constants';
import { observer } from 'mobx-react';
import { useContentWidth } from 'src/components/Responsive/WindowDimensions';

const handleImageClick = (
  history: any,
  locale: string,
  searchPageStore: SearchPageStore,
  newDesign: boolean
): void => {
  changeURL(history, locale, undefined, newDesign);
  searchPageStore.setSearchValue('');
  publishSingleMetricCount('SwishipLogoButtonClick');
};

export const HeaderComponent = observer(() => {
  const query = useQuery();
  const history = useNavigate();
  const locale = query.get(LOCALE_QUERY_PARAM) || '';
  const newDesign = query.has(DESIGN_ID_QUERY_PARAM);
  const searchPageStore = useContext(SearchPageContext);

  return (
    <Row width="100%" alignmentHorizontal="center">
      <Row width={useContentWidth()}>
        <Column alignmentHorizontal="start">
          <img
            id={'SwishipLogo'}
            src={logo}
            alt="Swiship"
            height="auto"
            width="90px"
            onClick={() => handleImageClick(history, locale, searchPageStore, newDesign)}
          />
        </Column>
        <Column alignmentHorizontal="end" width="100%">
          <LanguagePicker />
        </Column>
      </Row>
    </Row>
  );
});

export default HeaderComponent;
