import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import TrackingContent from './TrackingContent';
import OrderInfoContext from 'src/stores/OrderInfo';
import SearchPageContext from 'src/stores/SearchPageStore';
import { STATES, TRACKING_ID_QUERY_PARAM } from 'src/utils/Constants';
import CustomLoader from 'src/components/TrackPage/CustomLoader';
import { useQuery } from 'src/utils/URLUtils';
import BackendFailure from 'src/components/ErrorPage/BackendFailure';
import UnknownTrackingNumber from 'src/components/ErrorPage/UnknownTrackingNumber';
import HeaderComponent from 'src/components/HeaderComponent';
import AppLayout from '@amzn/meridian/app-layout';
import Masthead from '@amzn/meridian/masthead';
import Theme from '@amzn/meridian/theme';
import Box from '@amzn/meridian/box';
import { SearchPackagesContent } from 'src/components/SearchPackagesPage/SearchPackagesPage';

const getMainContent = (orderInfoState: string): any => {
  switch (orderInfoState) {
    case STATES.PENDING:
      return (
        <Box spacingInset="400">
          <CustomLoader />
        </Box>
      );
    case STATES.DONE:
      return <TrackingContent />;
    case STATES.ERROR:
      return <SearchPackagesContent error={<BackendFailure />} />;
    case STATES.UNKNOWN_TRACKING_NUMBER:
      return <SearchPackagesContent error={<UnknownTrackingNumber />} />;
    default:
      return null;
  }
};

export const TrackPackagesPage = observer(() => {
  const orderInfo = useContext(OrderInfoContext);
  const searchPageStore = useContext(SearchPageContext);
  const query = useQuery();
  const trackingId = useMemo(() => query.get(TRACKING_ID_QUERY_PARAM), [query]);

  useEffect(() => {
    if (trackingId) {
      searchPageStore.setSearchValue(trackingId);
      orderInfo.fetchAllPackageInfos(trackingId);
    }
    // eslint-disable-next-line
  }, [trackingId]);

  return (
    <AppLayout headerComponent={Masthead} backgroundColor="secondary" spacingInset="none">
      <Theme tokens={{ mastheadSpacingHorizontal: '0px' }}>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Masthead children={<HeaderComponent />} />
      </Theme>

      {getMainContent(orderInfo.getLoadingState)}
    </AppLayout>
  );
});

export default TrackPackagesPage;
