import React, { ReactElement } from 'react';

// December 19, 10:10 AM
export const DATE_AND_TIME = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});
// 10:10 AM
export const _12_HOUR_CLOCK_TIME = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
});
// Thursday, December 19
export const LONG_DATE = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
});

// Thursday, December 19
export const SHORT_DATE = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
});

export const italicize = (component: ReactElement): ReactElement => {
  return <div style={{ fontStyle: 'italic' }}> {component} </div>;
};

export const trim = (input: string): string => {
  return input.trim();
};

export const capitalizeFirstLetter = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};
