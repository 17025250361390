import { getDomainInfo, DomainInfo } from './DomainUtils';

export const scrollToTop = (): void => {
  const elmnt = document.getElementById('root');
  if (elmnt) {
    elmnt.scrollIntoView(true);
  }
};

export const DOMAIN_NAME: string | undefined =
  typeof window !== 'undefined' && window.location ? window.location.hostname : undefined;

export const DOMAIN_INFO: DomainInfo | undefined = DOMAIN_NAME
  ? getDomainInfo(DOMAIN_NAME)
  : undefined;
