import React, { FunctionComponent } from 'react';
import Responsive from '@amzn/meridian/responsive';

type DesktopViewProps = {
  children: React.ReactNode;
};

const DesktopView: FunctionComponent<DesktopViewProps> = ({ children }) => {
  return (
    <Responsive
      query="min-width"
      props={{
        mobile: { default: true, '780px': false },
      }}
    >
      {/*eslint-disable-next-line react/prop-types*/}
      {(props) => (props.mobile ? null : children)}
    </Responsive>
  );
};
export default DesktopView;
