import React from 'react';
import locales, { getLocaleFromDomain } from 'src/i18n/locales';
import { publishSingleMetricCount } from '../metrics';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Select, { SelectOption } from '@amzn/meridian/select';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  DESIGN_ID_QUERY_PARAM,
  LOCALE_QUERY_PARAM,
  TRACKING_ID_QUERY_PARAM,
} from 'src/utils/Constants';
import { changeURL, useQuery } from 'src/utils/URLUtils';
import { DOMAIN_NAME } from 'src/utils/WindowUtils';
import moment from 'moment';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import { localizationContextBuilder } from 'src/i18n/localizationManager';
import { logger } from 'src/logger';

function normalizeLocale(locale: string): string {
  // SellerCentral uses underscores for the locale but i18n expects dashes
  // en_US -> en-US
  return locale.replace('_', '-');
}

const getClosestLocale = (locale: string): string => {
  for (let i = 0; i < locales.length; i++) {
    if (locales[i].value === locale) {
      return locale;
    }
  }

  for (let i = 0; i < locales.length; i++) {
    if (locales[i].value.substr(0, 2) === locale.substr(0, 2)) {
      return locales[i].value;
    }
  }

  return 'en-US';
};

export const getLocaleBasedOnDomain = (locale: string | null): string => {
  if (locale) {
    return getClosestLocale(normalizeLocale(locale));
  } else {
    return DOMAIN_NAME
      ? normalizeLocale(getLocaleFromDomain(DOMAIN_NAME) || locales[0].value)
      : locales[0].value;
  }
};

export const changeMomentsLocale = (locale: string): void => {
  moment.locale(locale);
};

export const LanguagePicker = observer(() => {
  const history = useNavigate();
  const query = useQuery();
  const locale = getLocaleBasedOnDomain(query.get(LOCALE_QUERY_PARAM));
  const newDesign = query.has(DESIGN_ID_QUERY_PARAM);
  const trackingId = query.get(TRACKING_ID_QUERY_PARAM) || '';
  const { localizationContext, setLocalizationContext } = useLocalizationContext();

  const handleChange = (locale: string): any => {
    locale = locale || locales[0].value;
    changeMomentsLocale(locale);
    publishSingleMetricCount('LocaleChange');
    logger.info(`LocaleChange + ${locale}`);

    if (localizationContext.getLocale() !== locale) {
      setLocalizationContext(
        localizationContextBuilder
          .withLocale(localizationContext.getLocale() === locale ? 'de-DE' : locale)
          .build()
      );
    }
  };

  return (
    <div id="languagePicker">
      <Row>
        <Column>
          <Select
            data-testid="language-picker"
            size="small"
            placeholder="Select..."
            value={locale}
            onChange={(locale) => {
              handleChange(locale);
              changeURL(history, locale, trackingId, newDesign);
            }}
          >
            {locales.map((locale, index) => {
              return <SelectOption key={index} value={locale.value} label={locale.name} />;
            })}
          </Select>
        </Column>
      </Row>
    </div>
  );
});

export default LanguagePicker;
