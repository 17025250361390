/* istanbul ignore file */
import { MessageBundle } from '@amzn/arb-tools';
import translations from 'translations';
import arbManifest from 'translations/arbManifest';
import { DEFAULT_LOCALE } from 'src/utils/Constants';
import moment from 'moment';
import { getLocalizationContext } from 'src/i18n/localizationManager';
import { logger } from 'src/logger';
import { publishSingleMetricCount } from 'src/metrics';

export function t(
  bundle: MessageBundle,
  stringId: string | undefined,
  args?: Record<string, unknown>
): string | undefined {
  if (!bundle || !stringId) {
    return undefined;
  }
  try {
    if (!args) {
      return bundle.getMessage(stringId);
    }
    return bundle.formatMessage(stringId, args);
  } catch (error: unknown) {
    publishSingleMetricCount('StringTranslationError');
    if (error instanceof Error) {
      logger.warn(`Translation error for stringId: ${stringId}. Error: ${error.message}`);
    } else {
      logger.warn(`Translation error for stringId: ${stringId}. Error: ${String(error)}`);
    }
    return stringId;
  }
}

export function getMbmOptions(arbManifest: {
  endpointMap: Record<string, Record<string, string>>;
}): any {
  let currentLocale = DEFAULT_LOCALE;
  const documentLocale = document?.querySelector('html')!.getAttribute('lang');

  // Get the locale from URL query parameter
  const query = new URLSearchParams(window.location.search);
  const localeFromQuery = query.get('loc');

  if (localeFromQuery) {
    // If locale is present in URL, use it
    currentLocale = localeFromQuery; // Use localeFromQuery directly
  } else if (documentLocale) {
    // If not in URL, use document locale
    currentLocale = documentLocale;
  }

  try {
    if (moment && typeof moment.locale === 'function') {
      moment.locale(currentLocale);
    }
  } catch (error) {
    console.warn('Failed to set moment locale:', error);
  }

  const localizationContext = getLocalizationContext(currentLocale);
  return {
    arbManifest,
    defaultLocalizationContext: localizationContext,
    resolveBundleUrl: (resourcePackPath: string | undefined, locale: string, bundle: string) =>
      resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
  };
}

export function resolveBundleUrlWithFallbacks(
  resourcePackPath: string | undefined,
  locale: string,
  bundle: string
): any {
  if (resourcePackPath && translations[resourcePackPath]) {
    return translations[resourcePackPath];
  }

  if (!resourcePackPath && arbManifest.endpointMap?.[locale]?.[bundle]) {
    return translations[arbManifest.endpointMap[locale][bundle]];
  }

  if (arbManifest.endpointMap?.[DEFAULT_LOCALE]?.[bundle]) {
    return translations[arbManifest.endpointMap[DEFAULT_LOCALE][bundle]];
  }

  return undefined;
}

/**
 * Helper function used for translation, with singular/plural form and argument replacement
 * @param bundle MessageBundle object
 * @param keys translation keys for singular and plural forms
 * @param args arguments to replace placeholders
 * @param count number that will be used to choose singular/plural form
 */
export function getTranslationFromBundle(
  bundle: any,
  keys: string[],
  args?: Record<string, unknown>,
  count?: number
): string | undefined {
  if (!bundle || keys.length === 0) {
    return undefined;
  }

  const index = count !== undefined ? (count === 1 ? 0 : 1) : 0;
  const key = keys[index];

  if (!args) {
    return bundle.getMessage(key);
  }

  return bundle.formatMessage(key, {
    ...args,
    count: count !== undefined ? count : null,
  });
}
