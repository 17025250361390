import { makeObservable, observable, action } from 'mobx';
import { createContext } from 'react';

export class SearchPageStore {
  @observable protected _searchValue = '';

  constructor() {
    makeObservable(this);
  }

  get getSearchValue(): string {
    return this._searchValue;
  }

  @action
  setSearchValue(value: string): void {
    this._searchValue = value;
  }
}

export default createContext(new SearchPageStore());
