import React from 'react';
import { observer } from 'mobx-react';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import { TrackingEvent } from 'src/stores/TrackingEvent';
import Button from '@amzn/meridian/button';
import Table, { TableCell, TableRow, TableSectionHeader } from '@amzn/meridian/table';
import {
  getLocalizedMonthDayString,
  TRACKING_HISTORY_MINIMAL_NUMBER_OF_ROWS,
} from 'src/utils/Constants';
import moment from 'moment-timezone';
import 'src/components/TrackPackagesPage/TrackingHistoryComponent.scss';
import './TrackingHistoryComponent.scss';
import ZipCodeModal from 'src/components/TrackPackagesPage/ZipCodeModal';
import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import Tooltip from '@amzn/meridian/tooltip';
import { formatEventTime } from 'src/utils/Timer';

interface TrackingHistoryComponentProps {
  trackingEvents: TrackingEvent[][];
  isLoadMoreExpanded: boolean;
  loadMore: () => void;
  hasAdditionalDeliveryDetails: boolean;
  trackingNumber: string;
}

interface TrackingHistoryTableProps {
  trackingEvents: TrackingEvent[][];
  isLoadMoreExpanded: boolean;
  hasAdditionalDeliveryDetails: boolean;
  trackingNumber: string;
}

const TrackingHistoryTable: React.FC<TrackingHistoryTableProps> = observer(
  ({ trackingEvents, isLoadMoreExpanded, hasAdditionalDeliveryDetails, trackingNumber }) => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    const allEvents: [TrackingEvent, boolean][] = trackingEvents.flatMap(
      (sameDayEvents: TrackingEvent[]) => {
        return sameDayEvents.map((trackingEvent: TrackingEvent, index: number) => {
          return [trackingEvent, index === 0] as [TrackingEvent, boolean];
        });
      }
    );

    return (
      <Table showDividers={true}>
        {allEvents
          .slice(0, isLoadMoreExpanded ? allEvents.length : TRACKING_HISTORY_MINIMAL_NUMBER_OF_ROWS)
          .map((event: [TrackingEvent, boolean], index: number) => {
            const trackingEvent = event[0];
            const showDate = event[1];
            function key(key: string): string {
              return key + trackingEvent?.getEventDate?.getTime();
            }

            const eventDateComponent = (
              <Text type="b300" className="eventText">
                {trackingEvent?.getEventDate
                  ? getLocalizedMonthDayString(trackingEvent.getEventDate)
                  : ''}
              </Text>
            );

            const eventTime = trackingEvent?.getEventDate
              ? moment(trackingEvent.getEventDate)?.format('LT').toLowerCase()
              : '';

            const { shortTZ, fullTZ } = trackingEvent?.getEventDate
              ? formatEventTime(trackingEvent.getEventDate?.toISOString())
              : { shortTZ: '', fullTZ: '' };

            const header = (
              <TableSectionHeader key={key('date')}>{eventDateComponent}</TableSectionHeader>
            );
            const row = (
              <TableRow key={key('row')}>
                <TableCell
                  key={key('time')}
                  width="20%"
                  alignmentHorizontal="start"
                  alignmentVertical="top"
                >
                  <Text type="b300">
                    {eventTime}{' '}
                    <Tooltip position="top" title={fullTZ} id="timeZoneTooltip">
                      <span>{shortTZ}</span>
                    </Tooltip>
                  </Text>
                </TableCell>
                <TableCell key={key('event')} width="55%" alignmentVertical="top">
                  <Text type="b300">
                    {t(bundle, trackingEvent.getEventDescription)}{' '}
                    {index === 0 && hasAdditionalDeliveryDetails && (
                      <ZipCodeModal trackingNumber={trackingNumber} />
                    )}
                  </Text>
                </TableCell>
                <TableCell
                  key={key('address')}
                  alignmentHorizontal="end"
                  width="40%"
                  alignmentVertical="top"
                >
                  <Text type="b300">
                    {trackingEvent.getEventAddress.replace(/\s+/g, ' ').trim()}
                  </Text>
                </TableCell>
              </TableRow>
            );

            return showDate ? [header, row] : [row];
          })}
      </Table>
    );
  }
);

export const TrackingHistoryComponent: React.FC<TrackingHistoryComponentProps> = observer(
  ({
    trackingEvents,
    isLoadMoreExpanded,
    loadMore,
    hasAdditionalDeliveryDetails,
    trackingNumber,
  }) => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
    if (!trackingEvents || trackingEvents.length === 0) {
      return null;
    }

    const numberOfAllEvents = trackingEvents.reduce(
      (sum: number, sameDayEvents: TrackingEvent[]) => {
        return sum + sameDayEvents.length;
      },
      0
    );
    const isLoadMoreVisible =
      !isLoadMoreExpanded && numberOfAllEvents > TRACKING_HISTORY_MINIMAL_NUMBER_OF_ROWS;

    return (
      <Column width={'100%'}>
        &nbsp;
        <Text type="b200">{t(bundle, 'swiship_tracking_history')}</Text>
        <TrackingHistoryTable
          trackingEvents={trackingEvents}
          isLoadMoreExpanded={isLoadMoreExpanded}
          hasAdditionalDeliveryDetails={hasAdditionalDeliveryDetails}
          trackingNumber={trackingNumber}
        />
        {isLoadMoreVisible ? (
          <Column width="100%" alignmentHorizontal="center">
            <Button onClick={loadMore} type="link">
              {t(bundle, 'swiship_order_load_more')}
            </Button>
          </Column>
        ) : null}
      </Column>
    );
  }
);

export default TrackingHistoryComponent;
