import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from '@amzn/katal-metrics';
import { logger } from 'src/logger';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

export const backend = {
  getAllPackageInfosURL: '/api/getAllPackageInfo',
  getPackageTrackingDetailsURL: '/api/getPackageTrackingDetails',
  getPackageDeliveryDetailsURL: '/api/getPackageDeliveryDetails',
};

const publishErrorCodeMetrics = (
  ex: any,
  httpMetricsPublisher: KatalMetrics.Publisher,
  axiosRequestObject: AxiosRequestConfig
): any => {
  if (ex && ex.response && ex.response.status) {
    if (ex.response.status >= 400 && ex.response.status < 500) {
      logger.info(
        'Failed backend call for request: ' +
          JSON.stringify(axiosRequestObject) +
          ', with status code :' +
          ex.response.status +
          ' status message: ' +
          ex.response.statusText,
        ex
      );
      httpMetricsPublisher.publishCounterMonitor('4xxError', 1);
      httpMetricsPublisher.publishCounterMonitor('5xxError', 0);
      httpMetricsPublisher.publishCounterMonitor('OtherError', 0);
    } else if (ex.response.status >= 500 && ex.response.status < 600) {
      logger.error(
        'Failed backend call for request: ' +
          JSON.stringify(axiosRequestObject) +
          ', with status code :' +
          ex.response.status +
          ' status message: ' +
          ex.response.statusText,
        ex
      );
      httpMetricsPublisher.publishCounterMonitor('4xxError', 0);
      httpMetricsPublisher.publishCounterMonitor('5xxError', 1);
      httpMetricsPublisher.publishCounterMonitor('OtherError', 0);
    } else {
      logger.error(
        'Failed backend call for request: ' +
          JSON.stringify(axiosRequestObject) +
          ', with status code :' +
          ex.response.status +
          ' status message: ' +
          ex.response.statusText,
        ex
      );
      httpMetricsPublisher.publishCounterMonitor('4xxError', 0);
      httpMetricsPublisher.publishCounterMonitor('5xxError', 0);
      httpMetricsPublisher.publishCounterMonitor('OtherError', 1);
    }
    httpMetricsPublisher.publishCounterMonitor('UndefinedStatus', 0);
  } else {
    logger.error(
      'Failed backend call for request: ' +
        JSON.stringify(axiosRequestObject) +
        ', with exception :',
      ex
    );
    httpMetricsPublisher.publishCounterMonitor('UndefinedStatus', 1);
  }
};

async function callBackendWithMetrics(
  axiosRequestObject: AxiosRequestConfig,
  methodName: string,
  url: string,
  stringsToPublish: { [key: string]: string }
): Promise<AxiosResponse<any>> {
  const httpMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(methodName);
  const httpRequestMetric = new KatalMetrics.Metric.HttpRequest(methodName).withMonitor();
  httpRequestMetric.url = url;

  // 2- publish strings
  for (const [key, value] of Object.entries(stringsToPublish)) {
    httpMetricsPublisher.publish(new KatalMetrics.Metric.String(key, value));
  }

  let result;
  try {
    // 3- make axios call
    result = await axios(axiosRequestObject);
    httpRequestMetric.setSuccess();
    httpRequestMetric.statusCode = result.status;
    httpRequestMetric.statusText = result.statusText;
  } catch (ex: any) {
    httpRequestMetric.statusCode = ex && ex.response ? ex.response.status : '';
    httpRequestMetric.statusText = ex && ex.response ? ex.response.statusText : '';
    httpRequestMetric.setFailure();
    publishErrorCodeMetrics(ex, httpMetricsPublisher, axiosRequestObject);
    throw ex;
  } finally {
    httpMetricsPublisher.publish(httpRequestMetric);
  }

  return result;
}

export async function getAllPackageInfos(trackingNumber: string): Promise<AxiosResponse<any>> {
  const axiosRequest: AxiosRequestConfig = {
    method: 'post',
    url: backend.getAllPackageInfosURL,
    data: {
      trackingNumber: trackingNumber,
    },
  };

  return callBackendWithMetrics(axiosRequest, 'getAllPackageInfos', backend.getAllPackageInfosURL, {
    trackingId: trackingNumber,
  });
}

export async function getPackageTrackingDetails(
  trackingNumber: string,
  shipMethod: string
): Promise<AxiosResponse<any>> {
  const axiosRequest: AxiosRequestConfig = {
    method: 'post',
    url: backend.getPackageTrackingDetailsURL,
    data: {
      trackingNumber: trackingNumber,
      shipMethod: shipMethod,
    },
  };

  return callBackendWithMetrics(
    axiosRequest,
    'getPackageTrackingDetails',
    backend.getPackageTrackingDetailsURL,
    {
      trackingId: trackingNumber,
    }
  );
}

export async function getPackageDeliveryDetails(
  trackingNumber: string,
  deliveryPostalCode: string
): Promise<AxiosResponse<any>> {
  const axiosRequest: AxiosRequestConfig = {
    method: 'post',
    url: backend.getPackageDeliveryDetailsURL,
    data: {
      trackingNumber: trackingNumber,
      deliveryPostalCode: deliveryPostalCode,
    },
  };

  return callBackendWithMetrics(
    axiosRequest,
    'getPackageDeliveryDetails',
    backend.getPackageDeliveryDetailsURL,
    {
      trackingId: trackingNumber,
      deliveryPostalCode: deliveryPostalCode,
    }
  );
}
