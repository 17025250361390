import React, { useContext } from 'react';
import SearchField from '@amzn/meridian/search-field';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import SearchPageContext, { SearchPageStore } from 'src/stores/SearchPageStore';
import { DESIGN_ID_QUERY_PARAM, LOCALE_QUERY_PARAM } from 'src/utils/Constants';
import { changeURL, useQuery } from 'src/utils/URLUtils';
import { publishSingleMetricCount } from 'src/metrics';
import { trim } from 'src/utils/Formatters';
import { scrollToTop } from 'src/utils/WindowUtils';
import { useContentWidth } from 'src/components/Responsive/WindowDimensions';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { t } from 'src/helpers';

const DEFAULT_SEARCH_BOX_WIDTH = 340;

const setSearchValue = (searchValue: string, searchPageStore: SearchPageStore): void => {
  searchPageStore.setSearchValue(searchValue);
};

const handleSubmit = (
  searchPageStore: SearchPageStore,
  history: any,
  locale: string,
  newDesign: boolean
): void => {
  const trackingId = trim(searchPageStore.getSearchValue);
  searchPageStore.setSearchValue(trackingId);
  if (trackingId) {
    changeURL(history, locale, trackingId, newDesign);
    publishSingleMetricCount('SearchButtonClick');
    scrollToTop();
  }
};

export const SearchBox = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const history = useNavigate();
  const query = useQuery();
  const locale = query.get(LOCALE_QUERY_PARAM) || '';
  const newDesign = query.has(DESIGN_ID_QUERY_PARAM);
  const searchPageStore = useContext(SearchPageContext);

  const contentWidth = useContentWidth();
  const searchBoxWidth =
    contentWidth < DEFAULT_SEARCH_BOX_WIDTH ? contentWidth : DEFAULT_SEARCH_BOX_WIDTH;

  return (
    <div id="searchBox">
      <SearchField
        value={searchPageStore.getSearchValue}
        placeholder={t(bundle, 'swiship_order_tracking_number')}
        onChange={(value) => setSearchValue(value, searchPageStore)}
        onSubmit={() => {
          handleSubmit(searchPageStore, history, locale, newDesign);
        }}
        size="small"
        searchButton={false}
        width={searchBoxWidth}
      />
    </div>
  );
});

export default SearchBox;
