import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import Text from '@amzn/meridian/text';
import OrderInfoContext, { OrderInfo } from 'src/stores/OrderInfo';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import PackageComponent from 'src/components/TrackPackagesPage/PackageComponent';
import Pagination from '@amzn/meridian/pagination';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import { PACKAGES_COUNT_PER_PAGE, STATES } from 'src/utils/Constants';
import { logger } from 'src/logger';
import { useContentWidth, useIsMobileView } from 'src/components/Responsive/WindowDimensions';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/utils/Constants';
import { getTranslationFromBundle, t } from 'src/helpers';
import { MessageBundle } from '@amzn/arb-tools';
import ContactUsWidget from 'src/components/ContactUsWidget/ContactUsWidget';
import Link from '@amzn/meridian/link';

export enum PackageTrackingVisibility {
  NoTracking,
  ShortTracking,
  FullTracking,
}

const getShippingInformation = (orderInfo: OrderInfo, bundle: MessageBundle): any => {
  const shippedItemCount = orderInfo.getPackageInfos
    .filter((packageInfo) => packageInfo.getIsShipped)
    .map((packageInfo) => packageInfo.getItemCount)
    .reduce((sum, current) => sum + current, 0);

  const awaitingPickupByCarrierItemCount = orderInfo.getPackageInfos
    .filter((packageInfo) => !packageInfo.getIsShipped)
    .map((packageInfo) => packageInfo.getItemCount)
    .reduce((sum, current) => sum + current, 0);

  const shippedPackageCount = orderInfo.getPackageInfos.filter(
    (packageInfo) => packageInfo.getIsShipped
  ).length;

  const awaitingPickupByCarrierPackageCount = orderInfo.getPackageInfos.filter(
    (packageInfo) => !packageInfo.getIsShipped
  ).length;

  const unprocessedItemCount = orderInfo.getUnprocessedItemCount;

  const shippedStringId =
    shippedItemCount === 1
      ? shippedPackageCount === 1
        ? 'swiship_order_item_has_shipped_in_package'
        : 'swiship_order_item_has_shipped_in_packages'
      : shippedPackageCount === 1
        ? 'swiship_order_items_has_shipped_in_package'
        : 'swiship_order_items_has_shipped_in_packages';

  const shippedInformation = getTranslationFromBundle(bundle, [shippedStringId], {
    shipped: shippedItemCount,
    packages: shippedPackageCount,
  });

  const awaitingPickupByCarrierStringId =
    awaitingPickupByCarrierItemCount === 1
      ? awaitingPickupByCarrierPackageCount === 1
        ? 'swiship_order_item_has_awaiting_pickup_in_package'
        : 'swiship_order_item_has_awaiting_pickup_in_packages'
      : awaitingPickupByCarrierPackageCount === 1
        ? 'swiship_order_items_has_awaiting_pickup_in_package'
        : 'swiship_order_items_has_awaiting_pickup_in_packages';

  const awaitingPickupByCarrierSummary = getTranslationFromBundle(
    bundle,
    [awaitingPickupByCarrierStringId],
    {
      shipped: awaitingPickupByCarrierItemCount,
      packages: awaitingPickupByCarrierPackageCount,
    }
  );

  const awaitsShipment =
    unprocessedItemCount && unprocessedItemCount > 0
      ? getTranslationFromBundle(
          bundle,
          ['swiship_order_item_awaits_shipment', 'swiship_order_items_awaits_shipment'],
          {
            items: unprocessedItemCount,
          },
          unprocessedItemCount
        )
      : undefined;
  // Sample return: 2 items shipped in 1 package, 3 items awaiting pickup by carrier in 1 package, 2 items awaiting shipment
  return (
    <Text type="b300">
      {(shippedInformation && shippedItemCount > 0 ? shippedInformation : '') +
        (awaitingPickupByCarrierSummary && awaitingPickupByCarrierItemCount > 0
          ? (shippedItemCount > 0 ? ', ' : '') + awaitingPickupByCarrierSummary
          : '') +
        (awaitsShipment ? ', ' + awaitsShipment : '')}
    </Text>
  );
};

const fetchPackagesTrackingDetails = async (
  orderInfo: OrderInfo,
  pageNumber: number
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  const firstPackageIndex = (pageNumber - 1) * PACKAGES_COUNT_PER_PAGE;

  const packagesCount = orderInfo.getPackageInfos.length;
  const lastPackageIndex =
    firstPackageIndex + PACKAGES_COUNT_PER_PAGE < packagesCount
      ? firstPackageIndex + PACKAGES_COUNT_PER_PAGE
      : packagesCount;

  for (let index = firstPackageIndex; index < lastPackageIndex; ++index) {
    if (orderInfo.getPackageInfos[index].getLoadingState !== STATES.PENDING) {
      // Skipping already loaded packages
      continue;
    }

    orderInfo.getPackageInfos[index].fetchPackageTrackingDetails();
  }
};

const onGetSupportLinkClick = () => {
  // We currently do not have a better way to launch contact us widget.
  const chatButton = document.querySelector('button[class^="acOpenButton"]') as HTMLButtonElement | null;
  if (chatButton) {
      chatButton.click();
  }
};

export const TrackingContent = observer(() => {
  const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const orderInfo = useContext(OrderInfoContext);
  const packageInfo = orderInfo.getCurrentPackage;
  const packagesCount = orderInfo.getPackageInfos.length;
  const isAssistEnabled = orderInfo.getIsAssistEnabled;
  const isMobile = useIsMobileView();

  const [currentPage, setCurrentPage] = useState(1);
  const [packageVisibility, setPackagesVisibility] = useState(
    new Array<PackageTrackingVisibility>(packagesCount)
      .fill(PackageTrackingVisibility.NoTracking)
      .fill(PackageTrackingVisibility.ShortTracking, 0, 1)
  );

  if (!packageInfo) {
    logger.warn(
      'TrackingComponent: package information are missing for order:' + orderInfo.getOrderId
    );
    return null;
  }

  const showPagination = orderInfo.getPackageInfos.length > PACKAGES_COUNT_PER_PAGE;
  const numberOfPages = Math.ceil(orderInfo.getPackageInfos.length / PACKAGES_COUNT_PER_PAGE);

  const firstPackageIndex = (currentPage - 1) * PACKAGES_COUNT_PER_PAGE;
  const lastPackageIndex = firstPackageIndex + PACKAGES_COUNT_PER_PAGE;

  const slicedPackages = orderInfo.getPackageInfos.slice(firstPackageIndex, lastPackageIndex);

  return (
    <>
      <Table spacing="small" className="trackingContent" data-testid="tracking-content">
        <TableRow>
          <TableCell backgroundColor="secondary">
            <Column heights={'fit'} alignmentVertical="top" alignmentHorizontal='center'>
              <Row minWidth={isMobile ? undefined : '850px'} spacing='none' spacingInset='none'>
                <Column spacing="none" alignmentHorizontal="start" width="75%">
                  &nbsp;
                  <Text type="b100">
                    {t(bundle, 'swiship_tracking_id')}:&nbsp;
                    {packageInfo.getTrackingNumber}
                  </Text>
                  <Text type="h600">{t(bundle, 'swiship_track_your_packages')}</Text>
                  {getShippingInformation(orderInfo, bundle)}
                  &nbsp;
                </Column>
                {isAssistEnabled && (
                  <Column spacing="none" alignmentHorizontal="end" width="25%">
                      <Link data-testid="swiship_need_help_link" onClick={onGetSupportLinkClick}>
                          {t(bundle, 'swiship_need_help_link')}
                      </Link>
                  </Column>
                )}
              </Row>
              {slicedPackages.map((packageInfo, index) => {
                const packageIndex = index + (currentPage - 1) * PACKAGES_COUNT_PER_PAGE;
                return (
                  <Row key={index} spacing="400" className="card" backgroundColor="primary">
                    <PackageComponent
                      packageInfo={packageInfo}
                      packageVisibility={packageVisibility}
                      index={packageIndex}
                      setPackageVisibility={setPackagesVisibility}
                    />
                  </Row>
                );
              })}
              &nbsp;
              {showPagination ? (
                <Pagination
                  showSkipArrows={true}
                  numberOfPages={numberOfPages}
                  onChange={(pageNumber) => {
                    fetchPackagesTrackingDetails(orderInfo, pageNumber);
                    setCurrentPage(pageNumber);
                  }}
                  currentPage={currentPage}
                />
              ) : null}
              &nbsp;
            </Column>
          </TableCell>
        </TableRow>
      </Table>
      {isAssistEnabled ? <ContactUsWidget /> : null}
    </>
  );
});

export default TrackingContent;
