import React from 'react';
import { createRoot } from 'react-dom/client';
import { KatSpinner } from '@amzn/katal-react';
import './i18n';
import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './logger';
import '@amzn/katal-components/styles.css';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AppInitWrapper>
    <React.Suspense fallback={<KatSpinner size="large" />}>
      <App />
    </React.Suspense>
  </AppInitWrapper>
);
